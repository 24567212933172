import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { FAQsList } from "./FAQsList";
import * as S from "./FAQs.style";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../seoSrings";
import { helmetJsonLdProp } from "react-schemaorg";
import { FAQPage } from "schema-dts";

const FAQs = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet 
        script={[
          helmetJsonLdProp<FAQPage>({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
                {
                    "@type": "Question",
                    "name": "Which movies or shows can we watch? Can we play our own videos?",
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": `Yes, you can connect your OTT accounts or phone through Chromecast and play content of your choice. Please note that we do NOT provide any movies or shows or OTT account. We just do the setup for you using your own content and accounts.`
                    }
                },
                {
                    "@type": "Question",
                    "name": `What about Photography and Fog entry?`,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": `Photoshoot and Fog Entry can be added online while booking. You can also add these On-Spot on day of booking.`
                    }
                }
                // Add more Q&A pairs as needed
            ]
          }),
        ]}
        >
          <title>{seoStrings?.["faq"]?.["title"]}</title>
          <meta
            name="description"
            content={seoStrings?.["faq"]?.["description"]}
          />
          <meta name="keywords" content={seoStrings?.["faq"]?.["keyword"]} />
        </Helmet>
      </HelmetProvider>
      <Header />
      <Banner />

      <S.FAQsContainer>
        <S.Heading>FAQs</S.Heading>

        {FAQsList &&
          FAQsList.length > 0 &&
          FAQsList.map((faq: { summary: string; details: string }) => {
            return (
              <Accordion
                key={faq.summary.slice(0, 15)}
                style={{
                  borderRadius: "24px",
                  border: "1px solid #cbcbcb",
                  padding: "1.4rem",
                  margin: "1rem 0",
                  backgroundColor: "none",
                }}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "25px",
                  }}
                >
                  <Typography component={'div'} style={{ fontFamily: "Montserrat" }}>
                    <S.Summary>{faq.summary}</S.Summary>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    fontFamily: "Montserrat",
                  }}
                >
                  <Typography
                    component={'div'}
                    style={{
                      fontFamily: "Montserrat",
                    }}
                  >
                    <S.Details>{faq.details}</S.Details>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </S.FAQsContainer>

      <S.MwebFAQsContainer>
        <S.Heading>FAQs</S.Heading>

        {FAQsList &&
          FAQsList.length > 0 &&
          FAQsList.map((faq: { summary: string; details: string }) => {
            return (
              <Accordion
                key={faq.summary.slice(0, 15)}
                style={{
                  borderRadius: "24px",
                  border: "1px solid #cbcbcb",
                  padding: "0.4rem",
                  margin: "1rem 0",
                  backgroundColor: "none",
                }}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "25px",
                  }}
                >
                  <Typography component={'div'} style={{ fontFamily: "Montserrat" }}>
                    <S.Summary>{faq.summary}</S.Summary>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    fontFamily: "Montserrat",
                  }}
                >
                  <Typography
                    component={'div'}
                    style={{
                      fontFamily: "Montserrat",
                    }}
                  >
                    <S.Details>{faq.details}</S.Details>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </S.MwebFAQsContainer>

      <Footer />
      <CopyRight />
    </>
  );
};

export default FAQs;
