import styled from "styled-components";

export const WaitListFormContainer = styled.div`
  display: flex;
  flex-direction: row;

  // // tablet and mobile
  @media only screen and (max-width: 786px) {
    flex-direction: column;
  }
`;

export const Heading = styled.div`
  //   flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  /* border: 1px solid black; */
  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const RightHalf = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  // background-color: lightgrey;
  padding: 6rem 9rem;

  @media only screen and (max-width: 1210px) {
    padding: 2.5rem 1.5rem;
  }
`;

export const LeftHalf = styled.div`
  flex: 1.5;
  display: flex;
  // padding: 6rem 0 0 0;

  justify-content: flex-end;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const ImgContainer = styled.div`
  //   background-color: #d9d9d9;
  flex: 0.7;
  border-radius: 20px;
  //   width: 30rem;
  //   height: 20rem;
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const LabelBox = styled.label``;

export const InputContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
`;
