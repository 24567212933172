export const getBlogs = (state) => {
  return state?.blogs?.blogs;
};

export const getArticleInfo = (state) => {
  return state?.blogs?.articleInfo;
};

export const getPhoneNumber = (state) => {
  return state?.blogs?.phoneNumber;
};

export const getGalleryImage = (state) => {
  return state?.blogs?.galleryImages;
};

export const getUpdateBookingDetails = (state) => {
  return state?.blogs?.updateBookingDetails;
};

export const getUpdateBookingDetail = (state) => {
  return state?.blogs?.updateBookingDetail;
};
