import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "./features/Home/screens/Header/Header";
import Banner from "./features/Home/screens/Banner/Banner";
import seoStrings from "./seoSrings";
import Footer from "./features/Home/screens/Footer/Footer";
import CopyRight from "./features/Home/screens/CopyRight/CopyRight";
import { helmetJsonLdProp } from "react-schemaorg";
import { WebPage } from "schema-dts";
import { Link } from "react-router-dom";
import ButtonView from "./components/common/ButtonView";
import styled from "styled-components";

const ButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
  padding-bottom: 4rem;
`;

const PageNotFound = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet
                    script={[
                        helmetJsonLdProp<WebPage>({
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": "Page Not Found",
                            "description": "The requested page couldn't be found. Please check the URL or return to the homepage.",
                            "url": "https://thebingetown.com/404"
                        }),
                      ]}
                >
                <title>{seoStrings?.["pageNotFound"]?.["title"]}</title>
                <meta
                    name="description"
                    content={seoStrings?.["pageNotFound"]?.["description"]}
                />
                <meta
                    name="keywords"
                    content={seoStrings?.["pageNotFound"]?.["keyword"]}
                />
                </Helmet>
            </HelmetProvider>
            <Header />
            <Banner />
            <div className="PageNotFound" style={{justifyContent:'center',display:'flex',flexDirection: 'column',height:'20rem'}}>
                <>
                    <h1 style={{justifyContent:'center',display:'flex'}}>404 Error</h1>
                </>
                <>
                    <h1 style={{justifyContent:'center',display:'flex'}}>Page Not Found</h1>
                </>
                <ButtonContainer>
                    <Link
                        to="/"
                        title="Home Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                        style={{ textDecoration: "none", color: "#ffffff" }}
                    >
                        <ButtonView text={"Back To Home Page"} />
                    </Link>
                </ButtonContainer>
            </div>
            <Footer />
            <CopyRight />
        </>
    );
};

export default PageNotFound;