import { useSelector } from "react-redux";
import { getGalleryImages } from "../../../../redux/selectors/webConfigSelector";
import { CgArrowLongRight } from "react-icons/cg";
import { Gallery } from "../../../../components/common/Gallery";
import * as S from "./Gallery.style";

const GalleryPage = () => {
  const galleryImages = useSelector(getGalleryImages) || {};
  const { web = [], mobile = [] } = galleryImages || {};

  const handleAllPictures = () => {
    window.open("https://www.instagram.com/bingetown_in/");
  };

  return (
    <>
      <S.GalleryContainer>
        <S.Heading>100k+ followers on Instagram!</S.Heading>
        <Gallery images={web} />
        <S.ViewAllPictures onClick={handleAllPictures}>
          <S.MwebAllServicesTxt>Our Instagram page</S.MwebAllServicesTxt>
          <S.MwebAllServicesIcon>
            <CgArrowLongRight />
          </S.MwebAllServicesIcon>
        </S.ViewAllPictures>
      </S.GalleryContainer>

      <S.MwebGalleryContainer>
        <S.Heading>100k+ followers on Instagram!</S.Heading>
        <S.MwebPhotosContainer>
          <S.MwebPhotoContainer_1>
            <img
              src={mobile[0]}
              alt="galleryImage"
              title="galleryImage"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "12px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </S.MwebPhotoContainer_1>
          <S.MwebPhotoContainer_2>
            <img
              src={mobile[1]}
              alt="galleryImage"
              title="galleryImage"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "12px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </S.MwebPhotoContainer_2>
          <S.MwebRowContainer>
            <S.MwebPhotoContainer_3>
              <img
                src={mobile[2]}
                alt="galleryImage"
                title="galleryImage"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              />
            </S.MwebPhotoContainer_3>
            <S.MwebPhotoContainer_4>
              <img
                src={mobile[3]}
                alt="galleryImage"
                title="galleryImage"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              />
            </S.MwebPhotoContainer_4>
          </S.MwebRowContainer>
        </S.MwebPhotosContainer>
        <S.ViewAllPictures onClick={handleAllPictures}>
          <S.MwebAllServicesTxt>Our Instagram page</S.MwebAllServicesTxt>
          <S.MwebAllServicesIcon>
            <CgArrowLongRight />
          </S.MwebAllServicesIcon>
        </S.ViewAllPictures>
      </S.MwebGalleryContainer>
    </>
  );
};

export default GalleryPage;
