import City from "./components/City/City";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWebConfig } from "../../../../redux/actions/webConfigActions";
import { serviceAvailableAtCitiesSelector } from "../../../../redux/selectors/webConfigSelector";
import { fetchWebConfig } from "../../../../apis/ConfigAPI";
import * as S from "./FindUsInYourTown.style";

const FindUsInYourTown = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const citiesData = useSelector(serviceAvailableAtCitiesSelector);

  useEffect(() => {
    if (Object.keys(citiesData).length === 0) {
      fetchWebConfig()
        .then((response) => {
          dispatch(setWebConfig(response));
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [citiesData]);

  return (
    <S.FindUsInYourTownContainer>
      <S.Heading>Find us in your town</S.Heading>
      <S.SubHeading>
        <h1 style={{fontFamily:'inherit', fontSize:'inherit'}}>We have thirteen branches in three cities with 46 unique private celebrations
        spaces!</h1>
      </S.SubHeading>
      <S.Cities>
        {citiesData &&
          citiesData.length > 0 &&
          citiesData.map((city: any) => {
            return (
              <City
                isFromHomePage
                key={city?.cityName}
                title={city?.cityName}
                imgUrl={city?.cityImageURL}
                viewLocationOptions={city?.locations}
              />
            );
          })}
      </S.Cities>
      <S.MwebCityContainer>
        {citiesData &&
          citiesData.length > 0 &&
          citiesData.map((city: any) => {
            return (
              <City
                isFromHomePage
                key={city?.cityName}
                title={city?.cityName}
                imgUrl={city?.cityImageURL}
                viewLocationOptions={city?.locations}
              />
            );
          })}
      </S.MwebCityContainer>
    </S.FindUsInYourTownContainer>
  );
};

export default FindUsInYourTown;
