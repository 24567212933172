import styled from "styled-components";

export const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // padding: 3rem;
  flex: 1;
  @media only screen and (max-width: 786px) {
  }
`;

export const TownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  @media only screen and (max-width: 786px) {
    padding: 0rem 0;
  }
`;

export const CarouselView = styled.div``;

export const BackButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 2rem;

  @media only screen and (max-width: 786px) {
    margin: 2rem 1rem;
  }
`;
export const BackButtonTxt = styled.div``;

export const Heading = styled.div`
  flex: 1;
  font-size: 40px;
  font-weight: 600;
  line-height: 49px;
  margin: 0 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
    margin: 0.5rem 1.2rem;
  }
`;

export const SubHeading = styled.div`
  flex: 1;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  margin: 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    margin: 1.2rem;
  }
`;

export const Footer = styled.div`
  background-color: #edecea;
  padding: 4rem 8rem 8rem 8rem;

  @media only screen and (max-width: 786px) {
    padding: 2rem 1rem;
  }
`;

export const FooterText = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  @media only screen and (max-width: 786px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const FooterSubText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin: 1rem 0;

  @media only screen and (max-width: 786px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Container = styled.div`
  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const Cities = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  /* border: 1px solid black; */

  @media only screen and (max-width: 786px) {
    justify-content: center;
    align-items: center;
  }
`;

export const MwebCityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const CityContainer = styled.div`
  flex: 1;
  background-color: #ffffff;
  border: 1px solid #cacaca;
  padding: 1rem;
  border-radius: 16px;
  display: flex;
  gap: 1rem;
`;

export const CityImgContainer = styled.div`
  flex: 0.2;
  border-radius: 12px;
`;

export const CityTxtContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  // gap: 0.5rem;
  justify-content: space-around;
`;

export const CityContent = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const ViewLocationContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 1rem;
`;

export const ViewLocationTxt = styled.div`
  flex: 1;
  display: flex;
`;

export const DownArrowIcon = styled.div`
  // flex: 1;
  display: flex;
  justify-content: flex-end;
`;
