import webConfigReducer from "./redux/reducers/webConfigReducer";
import bookingReducer from "./redux/reducers/bookingReducer";

import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import blogsReducer from "./redux/reducers/blogsReducer";

const reducer = combineReducers({
  webConfig: webConfigReducer,
  booking: bookingReducer,
  blogs: blogsReducer,
});

const initialState = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
