import styled from "styled-components";
import LeftViewSection from "./components/LeftViewSection/LeftViewSection";
import RightViewSection from "./components/RightViewSection/RightViewSection";
import { useState, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import Header from "../../../Home/screens/Header/Header";
import CopyRight from "../../../Home/screens/CopyRight/CopyRight";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetActiveCakes,
  resetActiveDecorations,
  resetActiveGifts,
  resetActiveServices,
  resetBookingDetails,
  resetBookingSummaryAddons,
  resetExtraNumberOfPeople,
  resetSelectedOccasion,
  setCouponAppliedSuccessfully,
  setLocationsCityWise,
  setSelectedCity,
  setSelectedLocation,
  setTheaterInfo,
  setTheaterTimeSlots,
} from "../../../../redux/actions/bookingActions";
import {
  getLocationsCityWise,
  getSelectedCity,
  getSelectedLocation,
  getSelectedTheaterDate,
} from "../../../../redux/selectors/bookingSelector";
import {
  locationCodeMapSelector,
  serviceAvailableAtCitiesSelector,
} from "../../../../redux/selectors/webConfigSelector";
import * as S from "./TheaterDetails.style";
import { PROD } from "../../../../endPointsConfig";
import { seoFooterContent } from "../../utils";
import { fetchWebConfig } from "../../../../apis/ConfigAPI";
import { setWebConfig } from "../../../../redux/actions/webConfigActions";
import store from "../../../../store";
import { useSessionManagement } from "../../../../tracking/eventTracker";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../../seoSrings";
import { helmetJsonLdProp } from "react-schemaorg";
import { Place } from "schema-dts";
import { resetUpdateBookingDetail } from "../../../../redux/actions/blogsActions";

const BookingContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

const MwebBookingContainer = styled.div`
  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

const LeftViewSectionContainer = styled.div`
  flex: 1;
  background-color: #edecea;
  // @media only screen and (max-width: 786px) {
  //   display: none;
  // }
`;

const RightViewSectionContainer = styled.div`
  flex: 3;
  padding: 5rem 2rem;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

const CopyRightPageContainer = styled.div`
  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

const TheaterDetails = () => {
  const dispatch = useDispatch();
  let { location } = useParams();
  const selectedLocation = useSelector(getSelectedLocation);
  const selectedDate = useSelector(getSelectedTheaterDate) || new Date();
  const selectedCity = useSelector(getSelectedCity) || "";
  const showLocationsCityWise = useSelector(getLocationsCityWise) || {};
  const locationCodeMap = useSelector(locationCodeMapSelector) || {};

  useSessionManagement("THEATRE");

  const serviceAvailableAtCities =
    useSelector(serviceAvailableAtCitiesSelector) || [];
  const [locationName, setLocationName] = useState("");
  const [locationCode, setLocationCode] = useState("");

  useEffect(() => {
    if (Object.keys(serviceAvailableAtCities).length === 0) {
      fetchWebConfig()
        .then((response) => {
          dispatch(setWebConfig(response));
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [serviceAvailableAtCities]);

  useEffect(() => {
    if(!location || location === ""){
      let pathName = window.location.pathname.split('/')[1];
      let cityName = pathName.split('-')[3];
      console.log(pathName,cityName);
      for (let i = 0; i < serviceAvailableAtCities.length; i++) {
        if(cityName === serviceAvailableAtCities[i]?.cityName.toLowerCase()){
          let locationName = serviceAvailableAtCities[i]?.locations[0]?.locationName;
          for (let key in locationCodeMap) {
            if(key.toLowerCase() === locationName.toLowerCase()){
              window.history.replaceState("", "",`${window.location.pathname}/${key.toLowerCase()}`);
              setLocationName(locationName);
              setLocationCode(locationCodeMap[key]);
              store.dispatch(setSelectedCity(null, serviceAvailableAtCities[i]?.cityName));
              location = key.toLowerCase();
            }
          }
        }
      }
    } else if (selectedCity === "") {
        let locationName = "",
          cityName = "";
        for (let key in locationCodeMap) {
          if(key.toLowerCase() === location){
            locationName = key;
            setLocationName(locationName);
            setLocationCode(locationCodeMap[key]);
          }
        }

        for (let i = 0; i < serviceAvailableAtCities.length; i++) {
          let locationsArr = serviceAvailableAtCities[i]?.locations || [];
          for (let j = 0; j < locationsArr.length; j++) {
            if (locationsArr[j]?.locationName === locationName) {
              cityName = serviceAvailableAtCities[i]?.cityName;
            }
          }
        }
        if (cityName) store.dispatch(setSelectedCity(null, cityName));
      }

    store.dispatch(setLocationsCityWise());
  }, [serviceAvailableAtCities, location]);

  useEffect(() => {
    if (!selectedDate?.day || !selectedDate?.year || !selectedDate?.month)
      return;

    const date = `${selectedDate?.year}${selectedDate?.month}${selectedDate?.day}`;
    const theaterTimeSlots: any = {};

    selectedLocation &&
      selectedLocation.code &&
      selectedLocation.code.length > 0 &&
      selectedLocation.code.map((theaterCode: any) => {
        fetch(`${PROD}/getSlots?code=${theaterCode}_${date}&getDate=false`)
          .then((response) => response.json())
          .then((response) => {
            theaterTimeSlots[theaterCode] = response.slots;
          });
      });

    dispatch(setTheaterTimeSlots(theaterTimeSlots));
  }, [selectedDate, selectedLocation]);

  const [error, setError] = useState("");

  useEffect(() => {
    dispatch(resetBookingSummaryAddons());
    dispatch(resetActiveCakes());
    dispatch(resetActiveDecorations());
    dispatch(resetActiveGifts());
    dispatch(resetActiveServices());
    dispatch(resetExtraNumberOfPeople());
    dispatch(resetBookingDetails());
    dispatch(resetSelectedOccasion());
    dispatch(setCouponAppliedSuccessfully(false));
    dispatch(resetUpdateBookingDetail());
  }, []);

  useEffect(() => {
    let locationCode = "";
    for (let key in locationCodeMap) {
      if(key.toLowerCase() === location){
        locationCode = locationCodeMap[key];
      }
    }
    fetch(`${PROD}/webConfigv2?location=${locationCode}&newWeb=newWeb`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(setTheaterInfo(response));
        setLocationCode(locationCode);
      })
      .catch((error: any) => {
        setError(error);
      });
  }, [locationCodeMap,location]);

  let locationN: string = selectedLocation?.locationName;

  useEffect(() => {
    if (!locationCode || !selectedDate) return;
    const locName = Object.entries(locationCodeMap)?.filter(
      (loc) => loc[1] === locationCode
    )?.[0]?.[0];

    let location =
      showLocationsCityWise &&
      showLocationsCityWise.length > 0 &&
      showLocationsCityWise.filter(
        (location: {
          locationName: string;
          locationImageURL: string;
          code: object;
        }) => location.locationName === locName
      )[0];
    let color = "red",
      count = 0;
    const fetchCountColor = async (locationCode: string) => {
      const month = selectedDate?.month;
      const date = selectedDate?.day;
      const year = selectedDate?.year;
      try {
        const response = await fetch(
          `${PROD}/getSlots?getDate=false&date=${month}-${date}-${year}&location=${locationCode}`
        );
        let resp = await response.json();
        color = resp.color;
        count = resp.count;
      } catch (err) {
        console.log("EERRR", err);
      }
    };
    fetchCountColor(locationCode);
    dispatch(setSelectedLocation({ ...location, color, count }));
  }, [locationCode, showLocationsCityWise]);

  return (
    <>
      <HelmetProvider>
        {selectedLocation?.locationName && (
          <Helmet
          script={[
            helmetJsonLdProp<Place>({
              "@context": "https://schema.org",
              "@type": "Place",
              "name": `Binge Town Theater Selection in ${locationN}`,
              "description": `Select your preferred Theater in ${locationN}`,
              "url": `https://thebingetown.com/private-theaters-in-bangalore/${locationN}`
            }),
          ]}
          >
            <title>
              {seoStrings[locationN as keyof typeof seoStrings]["title"]}
            </title>
            <meta
              name="description"
              content={
                seoStrings[locationN as keyof typeof seoStrings]["description"]
              }
            />
            <meta
              name="keywords"
              content={
                seoStrings[locationN as keyof typeof seoStrings]["keyword"]
              }
            />
          </Helmet>
        )}
      </HelmetProvider>
      <Header />
      <BookingContainer>
        <LeftViewSectionContainer>
          <LeftViewSection
            showLocationsCityWise={showLocationsCityWise}
          ></LeftViewSection>
        </LeftViewSectionContainer>
        <RightViewSectionContainer>
          <RightViewSection></RightViewSection>
        </RightViewSectionContainer>
      </BookingContainer>
      <MwebBookingContainer>
        <LeftViewSectionContainer>
          <LeftViewSection
            showLocationsCityWise={showLocationsCityWise}
          ></LeftViewSection>
        </LeftViewSectionContainer>
        <RightViewSectionContainer>
          <RightViewSection></RightViewSection>
        </RightViewSectionContainer>
      </MwebBookingContainer>

      {/* <S.Footer>
        <Carousel
          interval={3000}
          indicatorContainerProps={{
            style: {
              display: "none",
            },
          }}
        >
          {seoFooterContent &&
            (selectedCity === "Bangalore"
              ? seoFooterContent.Bangalore
              : selectedCity === "Hyderabad"
              ? seoFooterContent.Hyderabad
              : seoFooterContent.Delhi
            ).map((item: any, i: any) => {
              return (
                <S.CarouselView key={`carousel_${i}`}>
                  <S.FooterText>{item.name}</S.FooterText>
                  <S.FooterSubText>{item.description}</S.FooterSubText>
                </S.CarouselView>
              );
            })}
        </Carousel>
      </S.Footer> */}
      <CopyRightPageContainer className="copyRightPage">
        <CopyRight />
      </CopyRightPageContainer>
    </>
  );
};

export default TheaterDetails;
