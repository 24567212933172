import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";
import Luxury_1 from "../../../assets/images/Luxury_1.jpeg";
import { useSelector } from "react-redux";
import { getAboutUsImageUrl } from "../../../redux/selectors/webConfigSelector";
import * as S from "./AboutUsPage.style";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../seoSrings";
import { helmetJsonLdProp } from "react-schemaorg";
import { AboutPage } from "schema-dts";

const AboutUsPage = () => {
  const aboutUsImageUrl = useSelector(getAboutUsImageUrl) || "";
  return (
    <>
      <HelmetProvider>
        <Helmet
        script={[
          helmetJsonLdProp<AboutPage>({
            "@context": "https://schema.org",
            "@type": "AboutPage",
            "name": "About The Binge Town",
            "description": `The Binge Town (BCKS Group) is India's #1 chain of Private
            Theaters. Customers can book our theaters to watch their favorite
            movies and shows on Big Screen and to celebrate special occassions
            like Birthdays, Anniversaries, etc. As of July 2024 the Binge
            Town has five branches in Bengaluru - Kormangala, Rajaji Nagar,
            Banashankari, Whitefield and Nagavara; four branches in Hyderabad
            - Jubilee Hills, Himayatnagar, Miyapur and Suchitra Circle ; and four branches in
            Delhi - Janakpuri, Laxmi Nagar, Saket and Karol Bagh`,
            "mainEntity": {
                "@type": "Organization",
                "name": "The Binge Town",
                "url": "https://thebingetown.com/"
            }
          }),
        ]}
        >
          <title>{seoStrings?.["aboutUs"]?.["title"]}</title>
          <meta
            name="description"
            content={seoStrings?.["aboutUs"]?.["description"]}
          />
          <meta
            name="keywords"
            content={seoStrings?.["aboutUs"]?.["keyword"]}
          />
        </Helmet>
      </HelmetProvider>
      <Header />
      <Banner />
      <S.AboutUsPageContainer>
        <S.Heading>About us</S.Heading>

        <S.ReviewContainer>
          <S.RightHalf>
            <S.CustomerReviewDesc>
              The Binge Town (BCKS Group) is India's #1 chain of Private
              Theaters. Customers can book our theaters to watch their favorite
              movies and shows on Big Screen and to celebrate special occassions
              like Birthdays, Anniversaries, etc. As of July 2024 the Binge
              Town has five branches in Bengaluru - Kormangala, Rajaji Nagar,
              Banashankari, Whitefield and Nagavara; four branches in Hyderabad
              - Jubilee Hills, Himayatnagar, Miyapur and Suchitra Circle; and four branches in
              Delhi - Janakpuri, Laxmi Nagar, Saket and Karol Bagh
            </S.CustomerReviewDesc>
            <S.CustomerReviewDesc>
              At Binge Town we focus on delivering happiness to our customers
              and make their special days extra special through our 5-star rated
              service. In addition to Theater and Decoration services, we also
              help in arranging cake, snacks and beverages for customers to make
              their experience more complete and perfect.
            </S.CustomerReviewDesc>
          </S.RightHalf>
          <S.LeftHalf>
            <img
              loading="lazy"
              src={aboutUsImageUrl || Luxury_1}
              alt="About us"
              title="About us"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "24px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </S.LeftHalf>
        </S.ReviewContainer>
      </S.AboutUsPageContainer>

      <S.MwebAboutUsPageContainer>
        <S.Heading>About us</S.Heading>
        <S.ReviewContainer>
          <S.LeftHalf>
            <img
              loading="lazy"
              src={aboutUsImageUrl || Luxury_1}
              alt="About us"
              title="About us"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "24px",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </S.LeftHalf>
          <S.RightHalf>
            <S.CustomerReviewDesc>
              The Binge Town (BCKS Group) is India's #1 chain of Private
              Theaters. Customers can book our theaters to watch their favorite
              movies and shows on Big Screen and to celebrate special occassions
              like Birthdays, Anniversaries, etc. As of July 2024 the Binge
              Town has five branches in Bengaluru - Kormangala, Rajaji Nagar,
              Banashankari, Whitefield and Nagavara; four branches in Hyderabad
              - Jubilee Hills, Himayatnagar, Miyapur and Suchitra Circle; and four branches in
              Delhi - Janakpuri, Laxmi Nagar, Saket and Karol Bagh
            </S.CustomerReviewDesc>
            <S.CustomerReviewDesc>
              At Binge Town we focus on delivering happiness to our customers
              and make their special days extra special through our 5-star rated
              service. In addition to Theater and Decoration services, we also
              help in arranging cake, snacks and beverages for customers to make
              their experience more complete and perfect.
            </S.CustomerReviewDesc>
          </S.RightHalf>
        </S.ReviewContainer>
      </S.MwebAboutUsPageContainer>
      <Footer />
      <CopyRight />
    </>
  );
};
export default AboutUsPage;
