import styled from "styled-components";

export const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #34255c;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 786px) {
    // padding: 3rem 3rem;
    display: none;
  }
`;

export const MwebGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background-color: #34255c;
  padding: 2rem 1.5rem;

  // tablet and mobile
  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  line-height: 49px;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const MwebPhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MwebPhotoContainer_1 = styled.div`
  height: 12.5rem;
  background-color: white;
  border-radius: 16px;
`;

export const MwebPhotoContainer_2 = styled.div`
  height: 11.5rem;
  background-color: white;
  border-radius: 16px;
`;

export const MwebRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const MwebPhotoContainer_3 = styled.div`
  height: 11.5rem;
  background-color: white;
  border-radius: 16px;
  flex: 1;
`;

export const MwebPhotoContainer_4 = styled.div`
  height: 11.5rem;
  background-color: white;
  border-radius: 16px;
  flex: 1;
`;

// ----

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: black;
  flex-shrink: 1;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const PhotoContainer_1 = styled.div`
  width: 29rem;
  height: 15.5rem;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
`;

export const PhotoContainer_2 = styled.div`
  width: 22.5rem;
  height: 24rem;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
`;

export const PhotoContainer_3 = styled.div`
  width: 16.5rem;
  height: 15.5rem;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
`;

export const PhotoContainer_4 = styled.div`
  width: 29rem;
  height: 24rem;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  flex-shrink: 1;
  display: flex;
`;

export const PhotoContainer_5 = styled.div`
  width: 22rem;
  height: 15.5rem;
  background-color: white;
  border-radius: 16px;
  // flex: 1;
`;

export const PhotoContainer_6 = styled.div`
  width: 16.5rem;
  height: 24rem;
  background-color: white;
  border-radius: 16px;
  // flex: 1;
`;

export const ViewAllPictures = styled.div`
  flex: 3;
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 18px;
  align-items: flex-end;
  color: #ffffff;
  text-decoration: underline;
  gap: 0.5rem;
  margin: 1.5rem 0;
`;

export const MwebAllServicesTxt = styled.div`
  display: flex;
`;

export const MwebAllServicesIcon = styled.div`
  display: flex;
`;
