import Direction from "../../../../../../assets/images/Theaters/Direction.svg";
import MenuIcon from "../../../../../../assets/images/Theaters/MenuIcon.svg";
import Calendar from "../../../../../../components/common/Calendar";

import { HiArrowLeft } from "react-icons/hi";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import {
  setSelectedLocation,
  setSelectedTheaterDate,
  setTheaterInfo,
} from "../../../../../../redux/actions/bookingActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { locationCodeMapSelector } from "../../../../../../redux/selectors/webConfigSelector";
import { setWebConfig } from "../../../../../../redux/actions/webConfigActions";
import {
  getSelectedCity,
  getSelectedLocation,
  getSelectedTheaterDate,
  getTheaterDetails,
} from "../../../../../../redux/selectors/bookingSelector";
import * as S from "./LeftViewSection.style";
import { fetchWebConfig } from "../../../../../../apis/ConfigAPI";
import { PROD } from "../../../../../../endPointsConfig";
import { useSessionManagement } from "../../../../../../tracking/eventTracker";
import Carousel from "react-material-ui-carousel";
import { seoFooterContent } from "../../../../utils";
import ButtonView from "../../../../../../components/common/ButtonView";
import useMediaQuery from "@mui/material/useMediaQuery";
import HaveDoubts from "../../../../../Home/screens/HaveDoubts/HaveDoubts";
import { Tooltip, Fade, Button } from "@mui/material";

const LeftViewSection = ({ showLocationsCityWise = [] }) => {
  const isMweb = useMediaQuery("(max-width:786px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [locationsOptions, setLocationsOptions] = useState<any>([]);
  const [error, setError] = useState("");

  const locationCodeMap = useSelector(locationCodeMapSelector) || {};
  const selectedLocation = useSelector(getSelectedLocation);
  const theaterDetails = useSelector(getTheaterDetails);
  const selectedDate = useSelector(getSelectedTheaterDate);
  const selectedCity = useSelector(getSelectedCity) || "";

  const addressText = theaterDetails && theaterDetails?.[0]?.address;
  const googleMapLocationUrl =
    theaterDetails && theaterDetails?.[0]?.googleMapUrl;
  const foodMenuUrl = theaterDetails && theaterDetails?.[0]?.menuUrl;

  const selectedLocationCode = locationCodeMap[selectedLocation?.locationName];
  const [activeLocation, setActiveLocation] = useState("");
  const [imgList,setImgList] = useState<any[]>([]);  
  const [locSlotAv,setLocSlotAv] = useState<any[]>([]);  

  useSessionManagement("LOCATION");

  useEffect(() => {
    if (!selectedDate?.year && !selectedDate?.month && !selectedDate?.day) {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      dispatch(
        setSelectedTheaterDate({
          year: year,
          month: month,
          day: day,
          fullDate: date,
        })
      );
    }
    if (
      !selectedDate?.year ||
      !selectedDate?.month ||
      !selectedDate?.day ||
      !selectedLocationCode
    )
      return;

    fetch(
      `${PROD}/getSlots?getDate=false&date=${selectedDate.month}-${selectedDate.day}-${selectedDate?.year}&location=${selectedLocationCode}`
    )
      .then((resp) => {
        return resp.json();
      })
      .then((res) => {
        dispatch(
          setSelectedLocation({
            ...selectedLocation,
            color: res.color,
            count: res.count,
          })
        );
      });
  }, []);

  useEffect(() => {
    if(!selectedDate){
      return;
    }
    let options: any[] = [];
    let locSlotAv: any[] = [];
    showLocationsCityWise &&
      showLocationsCityWise.length > 0 &&
      showLocationsCityWise.map((location: any, ind: number) => {
        const locationCode =
          locationCodeMap && locationCodeMap[location?.locationName];

        const fetchCountColor = async (locationCode: string) => {
          const month = selectedDate?.month;
          const date = selectedDate?.day;
          const year = selectedDate?.year;

          try {
            const response = await fetch(
              `${PROD}/getSlots?getDate=false&date=${month}-${date}-${year}&location=${locationCode}`
            );
            const { color = "red", count = 0 } = await response.json();
            options.push({
              label: (
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${location?.locationName} <span style="color: ${color}">(${count} available)</span>`,
                  }}
                />
              ),
              value: location?.locationName,
            });
            if(location?.locationName != selectedLocation?.locationName){
              locSlotAv.push([`${location?.locationName}`, `${count}`,`${color}`]);
            }
            if(location?.locationName === selectedLocation?.locationName){
              setActiveLocation(`${location?.locationName} <span style="color: ${color}">(${count} available)</span>`);
              const mediaResponse = await fetch(
                `${PROD}/getMediaUrls?location=${location?.locationName}`
              );
              const mediaList = await mediaResponse.json();
              let imageUrls = mediaList.sort((a:any, b:any) => b.pos-a.pos).filter((item:any) => item.type === "image" ).map((item:any) => item.url);
              setImgList(imageUrls);
            }
          } catch (err) {
            console.log("EERRR", err);
          }
        };
        fetchCountColor(locationCode);
      });
    setLocationsOptions(options);
    setLocSlotAv(locSlotAv);
  }, [showLocationsCityWise, selectedDate, selectedLocation]);

  useEffect(() => {
    if (!locationCodeMap) {
      fetchWebConfig()
        .then((response) => {
          dispatch(setWebConfig(response));
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [locationCodeMap]);

  const handleLocationChange = (e: any) => {
    // const locationCode = locationCodeMap && locationCodeMap[e?.value];
    let location =
      showLocationsCityWise &&
      showLocationsCityWise.length > 0 &&
      showLocationsCityWise.filter(
        (location: {
          locationName: string;
          locationImageURL: string;
          code: object;
        }) => location?.locationName === e?.value
      )[0];
    setActiveLocation(e?.label?.props?.dangerouslySetInnerHTML?.__html);
    dispatch(setSelectedLocation(location));

    navigate(`/private-theaters-in-${selectedCity.toLowerCase()}/${e?.value.toLowerCase()}`);
  };

  const handleFoodMenu = () => {
    window.open(foodMenuUrl, "_blank");
  };

  const handleTheaterProceed = () => {
    navigate(`/mweb-private-theaters-in-${selectedCity.toLowerCase()}/${selectedLocation?.locationName.toLowerCase()}`);
  }

  const handleRating = () => {
    window.open(`https://www.google.com/search?q=the+binge+town+-+${selectedLocation?.locationName}+&sca_esv=1cc85152d2d92d69&hl=en&biw=1536&bih=695&tbm=lcl&sxsrf=ADLYWIJNdcn0PbSlRkXp43zDR-EdxAuAlA%3A1717219993025&ei=mbJaZqiJAe2d4-EP5ZyXqAw&ved=0ahUKEwjoub391rmGAxXtzjgGHWXOBcUQ4dUDCAk&uact=5&oq=the+binge+town+-+&gs_lp=Eg1nd3Mtd2l6LWxvY2FsIhF0aGUgYmluZ2UgdG93biAtIDILEAAYgAQYkQIYigUyCxAAGIAEGJECGIoFMgsQABiABBiRAhiKBTILEAAYgAQYkQIYigUyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgARI3zNQhgZY6A9wAHgAkAEAmAHoAaAB1QuqAQUwLjEuNrgBA8gBAPgBAZgCBKACjgfCAgYQABgWGB7CAggQABiABBiiBJgDAIgGAZIHAzItNKAH4S0&sclient=gws-wiz-local#rlfi=hd:;si:;mv:[[29.5593349,78.56216239999999],[11.9835599,76.9943972]];tbs:lrf:!1m4!1u2!2m2!2m1!1e1!2m1!1e2!3sIAE,lf:1,lf_ui:1`, "_blank");
  };

  const handleClick = (item:any) =>{
    let locationCode = "";
    for (let key in locationCodeMap) {
      if(key.toLowerCase() === item[0].toLowerCase()){
        locationCode = locationCodeMap[key];
      }
    }
    fetch(`${PROD}/webConfigv2?location=${locationCode}&newWeb=newWeb`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(setTheaterInfo(response));
      })
      .catch((error: any) => {
        console.log(error);
      });
    dispatch(setSelectedLocation(showLocationsCityWise &&
    showLocationsCityWise.length > 0 &&
    showLocationsCityWise.filter(
      (location: {
        locationName: string;
        locationImageURL: string;
        code: object;
      }) => location.locationName.toLowerCase() === item[0].toLowerCase()
    )[0]));
    navigate(isMweb ? `/mweb-private-theaters-in-${selectedCity.toLowerCase()}/${item[0].toLowerCase()}` : `/private-theaters-in-${selectedCity.toLowerCase()}/${item[0].toLowerCase()}`);
  };

  return (
    <>
      <S.LeftViewSectionContainer>
        <S.BackButtonContainer onClick={() => navigate(-1)}>
          <HiArrowLeft size={24} />
          <S.BackButtonTxt>Back</S.BackButtonTxt>
        </S.BackButtonContainer>
        <S.LocationContainer>
          <S.LabelBox>Change Location in {selectedCity} </S.LabelBox>
          <Select
            placeholder="Select here"
            options={locationsOptions}
            onChange={handleLocationChange}
            value={{
              label: (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      activeLocation ||
                      `${selectedLocation?.locationName} <span style="color: ${selectedLocation?.color}">(${selectedLocation?.count} available)</span>`,
                  }}
                />
              ),
              value: selectedLocation?.locationName,
            }}
            styles={{
              menuList: (styles) => ({
                ...styles,
                padding: 0,
              }),
              control: (styles) => ({
                ...styles,
                backgroundColor: "white",
                // width: "22rem",
                height: "3rem",
                borderRadius: "12px",
                paddingBottom: 0,
                paddingTop: 0,
                borderWidth: "0.2rem",
                borderColor: "#8E8D8C"
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  // ...styles,
                  backgroundColor: "#edecea",
                  padding: "0.5rem",
                  height: "2rem",
                  display: "flex",
                  alignItems: "center",
                  borderBotom: "1px solid #cacaca",
                };
              },
            }}
            isSearchable={false}
          />
        </S.LocationContainer>
        <S.AddressContainer>
          <S.Address>
            <S.AddressHeading>Address:</S.AddressHeading>
            <S.AddressTxt>{addressText}</S.AddressTxt>
          </S.Address>
          <S.AddressDirection>
            <img
              src={Direction}
              alt="address_icon"
              title="address_icon"
              onClick={() => window.open(googleMapLocationUrl)}
              style={{width:"2rem", height:"auto"}}
            />
          </S.AddressDirection>
        </S.AddressContainer>
        <S.RatingContainer>
          <>
            <div
              style={{ color: "#34255C", textDecoration: "underline" }}
              onClick={handleRating}
            >
              (4.9 star Google Rating)
            </div>
            &nbsp; | &nbsp;
          </>
          <>
            <img src={MenuIcon} alt="menu" title="menu" />&nbsp;
            <div
              style={{ color: "#34255C", textDecoration: "underline" }}
              onClick={handleFoodMenu}
            >
              Menu
            </div>
            &nbsp;
          </>
        </S.RatingContainer>

        <S.DateContainer>
          <S.LabelBox>Select Date</S.LabelBox>
          <S.CalendarView>
            <Calendar />
          </S.CalendarView>
          <S.CalendarState>
            <S.SelectedContainer>
              <S.SelectedColorState></S.SelectedColorState>Selected
            </S.SelectedContainer>
            <S.NotAvailableContainer>
              <S.NotAvailableColorState></S.NotAvailableColorState>Not available
            </S.NotAvailableContainer>
          </S.CalendarState>
          {isMweb && activeLocation.substring( activeLocation.indexOf( '(' ) + 1, activeLocation.indexOf( ')' )).split(" ")[0] === "0" &&
            <Tooltip id="tooltip-disabled" title="Slots not available for selected date and location. Please change date or location to proceed." TransitionComponent={Fade} TransitionProps={{ timeout: 1000 }} followCursor enterTouchDelay={30}>
              <S.ButtonContainer >
                <ButtonView 
                  text={"Proceed"} 
                  disabled={true}   
                  styles={{ PointerEvent: 'none' }}               
                />
              </S.ButtonContainer>              
            </Tooltip>
          }
          {isMweb && !(activeLocation.substring( activeLocation.indexOf( '(' ) + 1, activeLocation.indexOf( ')' )).split(" ")[0] === "0") &&
          <S.ButtonContainer>
            <ButtonView 
              text={"Proceed"} 
              onClick={handleTheaterProceed}
            />
          </S.ButtonContainer>}
          {!isMweb && activeLocation.substring( activeLocation.indexOf( '(' ) + 1, activeLocation.indexOf( ')' )).split(" ")[0] === "0" &&
            <div style={{paddingTop:"1rem"}}>Slots not available for selected date and location. Please change date or location to proceed.</div>
          }
          <S.LinkHeading>Slots in other branches</S.LinkHeading>
          <S.LinkContainer>
            {locSlotAv.map((item:any)=>
            <Button style={{backgroundColor:"#ffffff", width: "48%", margin:"1%", padding: "0.5rem 0rem", whiteSpace: "pre-line" , textTransform: "none"}}
            onClick={()=>{
              handleClick(item);
            }}
            >
              <span>{item[0]+`\n`}
              <span style={{color: `${item[2]}`}}><span>&nbsp;</span> (Available Slots: {item[1]})</span></span>
            </Button>)}
          </S.LinkContainer>
        </S.DateContainer>
      </S.LeftViewSectionContainer>
      <HaveDoubts wrap={true}/>
      <S.Footer>
        <Carousel
          interval={3000}
          navButtonsAlwaysVisible={true}
          indicatorContainerProps={{
            style: {
              display: "none",
            },
          }}
          navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
                bottom: '1.5rem',
                top: 'unset'
            }
        }}
        >
          {seoFooterContent &&
            (selectedCity === "Bangalore"
              ? seoFooterContent.Bangalore
              : selectedCity === "Hyderabad"
              ? seoFooterContent.Hyderabad
              : seoFooterContent.Delhi
            ).map((item: any, i: any) => {
              return (
                <S.CarouselView key={`carousel_${i}`}>
                  {imgList.length > 0 && imgList[i] && <img src={imgList[i]} title={imgList[i]?.split('/')[6]} alt={imgList[i]?.split('/')[6]}
                    style={{ height: "18rem", aspectRatio: '3/2', objectFit: 'cover' }}
                  />}
                  <h2><S.FooterText>{item.name}</S.FooterText></h2>
                  <h3><S.FooterSubText>{item.description}</S.FooterSubText></h3>
                </S.CarouselView>
              );
            })}
        </Carousel>
      </S.Footer>
    </>
  );
};

export default LeftViewSection;
