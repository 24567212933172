export const getHoursTillBooking = (obj: any) => {
  let timeSlots = obj.time;
  let [hours, minutes] = covert12HrTo24HrFormat(timeSlots);
  let selectedDate = new Date(obj.date.toString().split("T")[0]);
  selectedDate.setHours(hours, minutes);
  let localDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );
  let diff = (selectedDate.getTime() - localDate.getTime()) / 3600000;

  return diff;
};

export const covert12HrTo24HrFormat = (timeSlot: any) => {
  const [time, modifier] = timeSlot.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return [hours, minutes];
};
