import styled from "styled-components";
import ButtonView from "../../../../components/common/ButtonView";
import { Link } from "react-router-dom";

const HowItWorksContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edecea;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2rem 1.5rem;
  }
`;

const ButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
`;

const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const HowItWorks = () => {
  return (
    <>
        <HowItWorksContainer>
            <Heading>How does Private Theater Celebration work?</Heading>
            <CustomerReviewDesc>Private Theatre celebration is a very new concept pioneered by The Binge Town. Guests can book private theaters for celebrating birthdays, anniversaries, date night, bride to be, etc and enjoy watching their favorite movies, shows, photos and videos on the big screen. Guests can also enjoy delicious food and beverages, and add other services like Cakes, Gifts, Photoshoot, etc. The Binge Town private theaters make celebrations easy, affordable, fun and memorable.</CustomerReviewDesc>
            <ButtonContainer>
                <Link
                to="/private-theaters-booking"
                title="City Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "#ffffff" }}
                >
                    <ButtonView text={"Book Now"} />
                </Link>
            </ButtonContainer>
      </HowItWorksContainer>
    </>
  );
};
export default HowItWorks;
