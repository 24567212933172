import { HiArrowLeft } from "react-icons/hi";
import BookingDetails from "../BookingDetails/BookingDetails";

import { IoIosStarOutline } from "react-icons/io";
import { MdOutlineCalendarToday } from "react-icons/md";
import { FaRegClock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dateFormat from "dateformat";
import {
  getSelectedTheater,
  getSelectedTheaterDate,
  getSelectedTheaterTime,
} from "../../../../../../redux/selectors/bookingSelector";
import * as S from "./LeftViewSection.style";
import { getUpdateBookingDetail } from "../../../../../../redux/selectors/blogsSelector";

const LeftViewSection = () => {
  const theaterTime = useSelector(getSelectedTheaterTime);
  const selectedTheaterDate = useSelector(getSelectedTheaterDate);
  const selectedTheater = useSelector(getSelectedTheater);
  const updateBookingDetail = useSelector(getUpdateBookingDetail) || {};

  const { name, location } = selectedTheater || {};

  const monthNumberToLabelMap = [
    null,
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const navigate = useNavigate();
  return (
    <S.LeftViewSectionContainer>
      <S.BackButtonContainer onClick={() => navigate(-1)}>
        <HiArrowLeft size={24} />
        <S.BackButtonTxt>Back</S.BackButtonTxt>
      </S.BackButtonContainer>

      <S.Overview>
        <S.OverviewHeading>Overview</S.OverviewHeading>
        <S.OverviewDetails>
          <S.Detail>
            <S.DetailIcon>
              <IoIosStarOutline size={24} />
            </S.DetailIcon>
            <S.DetailTxt>
              {updateBookingDetail?.theatre && updateBookingDetail?.location ? (
                <>
                  {updateBookingDetail?.theatre},{" "}
                  {updateBookingDetail?.location}
                </>
              ) : (
                <>
                  {name}, {location}
                </>
              )}
            </S.DetailTxt>
          </S.Detail>
          <S.Detail>
            <S.DetailIcon>
              <MdOutlineCalendarToday size={24} />
            </S.DetailIcon>
            <S.DetailTxt>
              {updateBookingDetail?.date ? (
                <>{dateFormat(updateBookingDetail?.date, "mmmm dS, yyyy")}</>
              ) : (
                <>
                  {`${selectedTheaterDate?.day} ${
                    monthNumberToLabelMap[selectedTheaterDate?.month]
                  }, ${selectedTheaterDate?.year}`}
                </>
              )}
            </S.DetailTxt>
          </S.Detail>
          <S.Detail>
            <S.DetailIcon>
              <FaRegClock size={24} />
            </S.DetailIcon>
            <S.DetailTxt>
              {updateBookingDetail?.time ? (
                <>{updateBookingDetail.time}</>
              ) : (
                <>{theaterTime}</>
              )}
            </S.DetailTxt>
          </S.Detail>
        </S.OverviewDetails>
      </S.Overview>

      <S.MwebOverview>
        <S.OverviewHeading>Overview</S.OverviewHeading>
        <S.OverviewDetails>
          <S.Detail>
            <S.DetailIcon>
              <IoIosStarOutline size={20} />
            </S.DetailIcon>
            <S.DetailTxt>
              {updateBookingDetail?.theatre && updateBookingDetail?.location ? (
                <>
                  {updateBookingDetail?.theatre},{" "}
                  {updateBookingDetail?.location}
                </>
              ) : (
                <>
                  {name}, {location}
                </>
              )}
            </S.DetailTxt>
          </S.Detail>
          <S.Detail>
            <S.DetailIcon>
              <MdOutlineCalendarToday size={20} />
            </S.DetailIcon>
            <S.DetailTxt>
              {updateBookingDetail?.date ? (
                <>{dateFormat(updateBookingDetail?.date, "mmmm dS, yyyy")}</>
              ) : (
                <>
                  {" "}
                  {dateFormat(selectedTheaterDate.fullDate, "mmmm dS, yyyy")}
                </>
              )}
            </S.DetailTxt>
          </S.Detail>
          <S.Detail>
            <S.DetailIcon>
              <FaRegClock size={20} />
            </S.DetailIcon>
            <S.DetailTxt>
              {" "}
              {updateBookingDetail?.time ? (
                <>{updateBookingDetail.time}</>
              ) : (
                <>{theaterTime}</>
              )}
            </S.DetailTxt>
          </S.Detail>
        </S.OverviewDetails>
      </S.MwebOverview>

      <BookingDetails />
    </S.LeftViewSectionContainer>
  );
};

export default LeftViewSection;
