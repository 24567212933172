import styled from "styled-components";

export const LeftViewSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3rem 2rem;
  gap: 0.5rem;
  background-color: #edecea;

  @media only screen and (max-width: 1000px) {
    padding: 0;
  }
`;

export const CalendarView = styled.div`
  background-color: white;
  border-radius: 24px;
  display: flex;
  flex-wrap: wrap;
   @media only screen and (max-width: 1000px) {
    margin-top: 0.5rem;
  }
`;

export const CalendarState = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
  @media only screen and (max-width: 786px) {
    margin-top: 0.5rem;
  }
`;

export const SelectedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectedColorState = styled.div`
  display: flex;
  width: 0.7rem;
  height: 0.7rem;
  background-color: #34255c;
  border-radius: 50%;
  margin-right: 0.2rem;
`;

export const AvailableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AvailableColorState = styled.div`
  display: flex;
  width: 0.7rem;
  height: 0.7rem;
  background-color: #1c9b21;
  border-radius: 50%;
  margin-right: 0.2rem;
`;

export const NotAvailableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NotAvailableColorState = styled.div`
  display: flex;
  width: 0.7rem;
  height: 0.7rem;
  background-color: #e5e5e5;
  border-radius: 50%;
  border: 0.1px solid black;
  margin-right: 0.2rem;
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 0.5rem;
    gap: 0.5rem;
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 1000px) {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
`;

export const Address = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 3px;
`;

export const AddressHeading = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

export const AddressTxt = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddressDirection = styled.div`
  display: flex;
`;

export const FoodMenuContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 1000px) {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 1000px) {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1000px) {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
`;

export const OptionBox = styled.option`
  top: 18rem;
  position: absolute;
`;

export const BackButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media only screen and (max-width: 1000px) {
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
`;

export const LabelBox = styled.label`
  margin: 1rem 1rem 0 0;
  @media only screen and (max-width: 1000px) {
    margin: 0;
  }
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

export const BackButtonTxt = styled.div``;

export const CarouselView = styled.div``;

export const Footer = styled.div`
  background-color: #edecea;
  padding: 1rem 2rem 2rem 2rem;

  @media only screen and (max-width: 786px) {
    padding: 2rem 2rem;
  }
`;

export const FooterText = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  @media only screen and (max-width: 786px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const FooterSubText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin: 1rem 0;

  @media only screen and (max-width: 786px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const ButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

export const LinkContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  
  @media only screen and (max-width: 786px) {
    margin-top: 0.3rem;
    padding-bottom: 1rem;
  }
`;

export const LinkHeading = styled.div`
  display: flex;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  @media only screen and (max-width: 786px) {
    margin-top: 0rem;
  }
`;