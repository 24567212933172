import TheBingeTownLogo from "../../../../assets/images/TheBingeTownLogo.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

import { SlLocationPin } from "react-icons/sl";
import { MdOutlineEmail } from "react-icons/md";
import { ImWhatsapp } from "react-icons/im";
import { BsInstagram } from "react-icons/bs";
import { FiLinkedin } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";
import { TbBrandYoutube } from "react-icons/tb";
import { FiFacebook } from "react-icons/fi";
import { getContactUsPage } from "../../../../redux/selectors/webConfigSelector";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchWebConfig } from "../../../../apis/ConfigAPI";
import { setWebConfig } from "../../../../redux/actions/webConfigActions";
import * as S from "./Footer.style";

const Footer = () => {
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:1000px)");
  let paddingStyle = matches ? "7.5rem 9rem" : "2rem 1.5rem";
  const contactUsPageInfo = useSelector(getContactUsPage);
  const [error, setError] = useState("");

  const { whatsappNumber, emailAddress, officeLocation } = contactUsPageInfo;

  useEffect(() => {
    if (!whatsappNumber || !emailAddress) {
      fetchWebConfig()
        .then((response) => {
          dispatch(setWebConfig(response));
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [whatsappNumber, emailAddress]);

  return (
    <S.FooterContainer style={{ padding: paddingStyle }}>
      <S.LogoContainer>
        <img src={TheBingeTownLogo} alt="TheBingeTown" title="TheBingeTown"/>
      </S.LogoContainer>
      <S.CompanyServicesContainer>
        <S.Heading>Company</S.Heading>
        <S.ServicesContainer>
          <S.Services>
            <Link
              to="/our_services"
              title="Our Services | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
              style={{ textDecoration: "none", color: "black" }}
            >
              Our services
            </Link>
          </S.Services>
          <S.Services>
            <Link
              to="/about_us"
              title="About Us | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
              style={{ textDecoration: "none", color: "black" }}
            >
              About Us
            </Link>
          </S.Services>
          <S.Services>
            <Link
              to="/login"
              title="Manage My Bookings | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
              style={{ textDecoration: "none", color: "black" }}
            >
              My Bookings
            </Link>
          </S.Services>
          <S.Services>
            <Link
              to="/gallery"
              title="Gallery | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
              style={{ textDecoration: "none", color: "black" }}
            >
              Gallery
            </Link>
          </S.Services>
          <S.Services>
            <Link
              to="/blogs"
              title="Blogs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
              style={{ textDecoration: "none", color: "black" }}
            >
              Blogs
            </Link>
          </S.Services>
          <S.Services>
            <Link to="/faqs" title="Faqs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town" style={{ textDecoration: "none", color: "black" }}>
              FAQs
            </Link>
          </S.Services>
        </S.ServicesContainer>
        <S.MWebServicesContainer>
          <S.ColumnContainer>
            <S.Services>
              <Link
                to="/our_services"
                title="Our Services | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                Our services
              </Link>
            </S.Services>
            <S.Services>
              <Link
                to="/about_us"
                title="About Us | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                About Us
              </Link>
            </S.Services>
            <S.Services>
              <Link
                to="/login"
                title="Manage My Bookings | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                My Bookings
              </Link>
            </S.Services>
          </S.ColumnContainer>
          <S.ColumnContainer>
            <S.Services>
              <Link
                to="/gallery"
                title="Gallery | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                Gallery
              </Link>
            </S.Services>
            <S.Services>
              <Link
                to="/blogs"
                title="Blogs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                Blogs
              </Link>
            </S.Services>
            <S.Services>
              <Link
                to="/faqs"
                title="Faqs | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
                style={{ textDecoration: "none", color: "black" }}
              >
                FAQs
              </Link>
            </S.Services>
          </S.ColumnContainer>
        </S.MWebServicesContainer>
      </S.CompanyServicesContainer>
      <S.ReachUsContainer>
        <S.Heading>Reach Us</S.Heading>
        <S.ReachUsDetails>
          <S.ReachUsIcon>
            <SlLocationPin size={24} />
          </S.ReachUsIcon>
          <S.ReachUsText>
            <S.AddressLine>10/1B, Graphite India Road, Hoodi</S.AddressLine>
            <S.AddressLine>Village, KR Puram, Bengaluru,</S.AddressLine>
            <S.AddressLine>560048, Karnataka, India</S.AddressLine>
          </S.ReachUsText>
        </S.ReachUsDetails>

        <S.ReachUsDetails>
          <S.ReachUsIcon>
            <ImWhatsapp size={24} />
          </S.ReachUsIcon>
          <S.ReachUsText>{whatsappNumber}</S.ReachUsText>
        </S.ReachUsDetails>

        <S.ReachUsDetails>
          <S.ReachUsIcon>
            <MdOutlineEmail size={24} />
          </S.ReachUsIcon>
          <S.ReachUsText> {emailAddress}</S.ReachUsText>
        </S.ReachUsDetails>
      </S.ReachUsContainer>
      <S.SocialMediaContainer>
        <S.Heading>Social Media</S.Heading>
        <S.SocialMediaLogo>
          <ImWhatsapp
            size={24}
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=918618976974&text&type=phone_number&app_absent=0"
              )
            }
          />
          <BsInstagram
            size={24}
            onClick={() =>
              window.open("https://www.instagram.com/bingetown_in/")
            }
          />
          <TbBrandYoutube
            size={24}
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCkUByxBsPjV_RCbOQx-v_IQ"
              )
            }
          />
          <FiFacebook
            size={24}
            onClick={() => window.open("https://www.facebook.com/thebingetown")}
          />
          <FiLinkedin
            size={24}
            onClick={() =>
              window.open("https://www.linkedin.com/company/the-binge-town/")
            }
          />
          <FiTwitter
            size={24}
            onClick={() => window.open("https://twitter.com/thebingetown")}
          />
        </S.SocialMediaLogo>
      </S.SocialMediaContainer>
    </S.FooterContainer>
  );
};
export default Footer;
