import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
`;

export const LogoContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: flex-start;
  margin-left: 3%;
  height: 75%;

  width: 80px;
  height: 62px;

  @media only screen and (max-width: 786px) {
    width: 51px;
    height: 40px;
  }
`;

export const HeaderOptions = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
  @media only screen and (max-width: 1200px) {
    flex: 2;
    align-items: space-around;
  }
  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const MenuContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: flex-end;
  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const LearnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LearnTxt = styled.div`
  // display: flex;
`;

export const LearnDownArrowIcon = styled.div`
  // display: flex;
`;

export const OptionContainer = styled.div`
  padding: 0.5rem;
`;

export const MenuView = styled.div`
  display: flex;
  flex-direction: column;
  width: 83%;
  gap: 1.5rem;
  padding: 2rem;
  position: absolute;
  top: 3.5rem;
  left: 0rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #34255c24;
  border-radius: 0px 0px 1rem 1rem;
  z-index: 10;

  // @media only screen and (min-width: 786px) {
  //   display: none;
  // }
`;

export const LearnMoreOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  gap: 1.5rem;
  // padding: 2rem;
  position: relative;
  // top: 3.5rem;
  left: 1em;
  // background-color: #ffffff;
  // box-shadow: 0px 4px 4px 0px #34255c24;
  // border-radius: 0px 0px 1rem 1rem;
  // z-index: 10;

  // @media only screen and (min-width: 786px) {
  //   display: none;
  // }
`;
