import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Carousel from "react-material-ui-carousel";

// icons
import { HiArrowLeft } from "react-icons/hi";

//redux
import { useSelector, useDispatch } from "react-redux";
import { serviceAvailableAtCitiesSelector } from "../../../../redux/selectors/webConfigSelector";
import { setSelectedTheaterDate } from "../../../../redux/actions/bookingActions";
import { setWebConfig } from "../../../../redux/actions/webConfigActions";

//components
import Header from "../../../Home/screens/Header/Header";
import CopyRight from "../../../Home/screens/CopyRight/CopyRight";
import City from "../../../Home/screens/FindUsInYourTown/components/City/City";

import * as S from "./LocationSelection.style";
import { fetchWebConfig } from "../../../../apis/ConfigAPI";
import { getSelectedTheaterDate } from "../../../../redux/selectors/bookingSelector";

import { seoFooterContent } from "../../utils";
import { useSessionManagement } from "../../../../tracking/eventTracker";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../../seoSrings";
import { helmetJsonLdProp } from "react-schemaorg";
import { Place } from "schema-dts";

const LocationSelection = () => {
  const selectedDate = useSelector(getSelectedTheaterDate) || {};

  useSessionManagement("LOCATION");

  useEffect(() => {
    if (!selectedDate?.year && !selectedDate?.month && !selectedDate?.day) {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      dispatch(
        setSelectedTheaterDate({
          year: year,
          month: month,
          day: day,
          fullDate: date,
        })
      );
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const citiesData = useSelector(serviceAvailableAtCitiesSelector);
  const [error, setError] = useState("");

  useEffect(() => {
    if (Object.keys(citiesData).length === 0) {
      fetchWebConfig()
        .then((response) => {
          dispatch(setWebConfig(response));
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [citiesData]);

  return (
    <>
        <HelmetProvider>
        <Helmet
          script={[
            helmetJsonLdProp<Place>({
              "@context": "https://schema.org",
              "@type": "Place",
              "name": "Binge Town Location Selection",
              "description": "Select your preferred location.",
              "url": "https://thebingetown.com/private-theaters-booking"
            }),
          ]}
        >
          <title>{seoStrings['home2']['title']}</title>
          <meta
                name="description"
                content={seoStrings['home2']['description']}
            />
          <meta
                name="keywords"
                content={seoStrings['home2']['keyword']}
            />
        </Helmet>
      </HelmetProvider>
      <Header />
      <S.LocationsContainer>
        <S.TownContainer>
          <S.BackButtonContainer onClick={() => navigate(-1)}>
            <HiArrowLeft size={24} />
            <S.BackButtonTxt>Back</S.BackButtonTxt>
          </S.BackButtonContainer>

          <S.Heading>Find us in your town</S.Heading>
          <h1><S.SubHeading>
          We have thirteen branches in three cities with 46 unique private
            celebration spaces!
          </S.SubHeading></h1>

          <S.Container>
            <S.Cities>
              {citiesData &&
                citiesData.length > 0 &&
                citiesData.map((city: any) => {
                  return (
                    <City
                      isFromHomePage={false}
                      key={city?.cityName}
                      title={city?.cityName}
                      imgUrl={city?.cityImageURL}
                      viewLocationOptions={city?.locations}
                    />
                  );
                })}
            </S.Cities>
          </S.Container>

          <S.MwebCityContainer>
            {citiesData &&
              citiesData.length > 0 &&
              citiesData.map((city: any) => {
                return (
                  <City
                    isFromHomePage={false}
                    key={city?.cityName}
                    title={city?.cityName}
                    imgUrl={city?.cityImageURL}
                    viewLocationOptions={city?.locations}
                  />
                );
              })}
          </S.MwebCityContainer>
        </S.TownContainer>
        <S.Footer>
          <Carousel
            interval={3000}
            navButtonsWrapperProps={
              {
                // style: {
                //   top: "14.5rem",
                // },
              }
            }
            indicatorContainerProps={{
              style: {
                // paddingBottom: "2rem",
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                backgroundColor: "#34255C",
                width: "5rem",
                borderRadius: "8px",
                color: "#34255C",
              },
            }}
          >
            {seoFooterContent &&
                seoFooterContent.City.map((item: any, i: any) => {
                return (
                  <S.CarouselView key={`carousel_${i}`}>
                    <h2><S.FooterText>{item?.name}</S.FooterText></h2>
                    {item?.description &&
                      item?.description.length > 0 &&
                      item.description.map((desc:any, ind:any) => {
                        return (
                          <h3><S.FooterSubText key={`desc_${ind}`}>
                            {desc}
                          </S.FooterSubText></h3>
                        );
                      })}
                  </S.CarouselView>
                );
              })}
          </Carousel>
        </S.Footer>
      </S.LocationsContainer>
      <CopyRight />
    </>
  );
};
export default LocationSelection;
