import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import useMediaQuery from "@mui/material/useMediaQuery";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  setCouponAppliedSuccessfully,
  setSelectedTheaterDate,
  setSelectedTheaterDay,
  setSelectedTheaterMonth,
  setSelectedTheaterTime,
  setSelectedTimeSlotInd,
} from "../../redux/actions/bookingActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedCity,
  getSelectedLocation,
  getSelectedTheaterDate,
} from "../../redux/selectors/bookingSelector";
import { locationCodeMapSelector } from "../../redux/selectors/webConfigSelector";
import { PROD } from "../../endPointsConfig";

// Custom theme for MUI components
const customTheme = createTheme({
  components: {
    MuiButtonBase: {
      root: {
        backgroundColor: "white",
        "&.Mui-selected": {
          backgroundColor: "#34255c",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          "&.Mui-selected": {
            backgroundColor: "#34255c",
          },
        },
      },
    },
  },
} as any);

// Custom day rendering component for the date picker
function ServerDay(
  props: PickersDayProps<Dayjs> & {
    highlightedDays?: number[];
    selectedDay?: number[];
    startDate?: Dayjs;
  }
) {
  const {
    highlightedDays = [],
    selectedDay = [],
    day,
    outsideCurrentMonth,
    startDate,
    ...other
  } = props;

  const isNotAvailable = highlightedDays.indexOf(props.day.date()) >= 0;
  const isBeforeStartDate = startDate && day.isBefore(startDate, "day") || day.isBefore(dayjs(), "day");

  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      invisible={isNotAvailable || isBeforeStartDate}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disabled={isNotAvailable || isBeforeStartDate}
      />
    </Badge>
  );
}

// Main calendar component
export default function Calendar() {
  const navigate = useNavigate();
  const isMweb = useMediaQuery("(max-width:786px)");

  const [isLoading, setIsLoading] = useState(false);
  const [highlightedDays, setHighlightedDays] = useState<Number[]>([]);
  const [selectedDay, setSelectedDay] = useState<Number[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const selectedDate = useSelector(getSelectedTheaterDate) || {};
  const selectedCity = useSelector(getSelectedCity);

  const dispatch = useDispatch();
  const location = useSelector(getSelectedLocation);
  const locationCodeMap = useSelector(locationCodeMapSelector);
  const selectedTheaterDate = useSelector(getSelectedTheaterDate);

  const month = selectedTheaterDate?.month;
  const year = selectedTheaterDate?.year;
  const locationCode =
    locationCodeMap && locationCodeMap[location?.locationName];

  // Fetch highlighted days from the server
  const fetchHighlightedDays = (month: any, year: any, locationCode: any, isCurrentMonth: boolean) => {
    setIsLoading(true);
    fetch(
      `${PROD}/getSlots?getDate=true&code=${month}_${year}&location=${locationCode}&theater=false`
    )
      .then((response) => response.json())
      .then((response) => {
        const { days = [], startDate } = response || {};
        let filteredDays = [];
        for (let i = 0; i < days?.length; i++) {
          filteredDays.push(Number(days[i]?.date?.split("-")?.[1]));
        }
        setHighlightedDays(filteredDays);
        const parsedStartDate = dayjs(startDate,  "YYYY-MM-DD");
        setStartDate(parsedStartDate);

        // Only set the start date as the default selected date if it's the initial load and for the current month
        if (isCurrentMonth && isInitialLoad) {
          dispatch(
            setSelectedTheaterDate({
              year: parsedStartDate.year(),
              month: parsedStartDate.month() + 1,
              day: parsedStartDate.date(),
              fullDate: parsedStartDate.toDate(),
            })
          );
          setSelectedDay([startDate]);
          setIsInitialLoad(false);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  // Fetch highlighted days when month, year, or locationCode changes
  useEffect(() => {
    if (!month || !year || !locationCode) return;
    const isCurrentMonth = month === (new Date().getMonth() + 1) && year === new Date().getFullYear();
    fetchHighlightedDays(month, year, locationCode, isCurrentMonth);
  }, [month, year, locationCode]);

  // Handle calendar date selection
  const handleCalendarDate = (newVal: any) => {
    dispatch(
      setSelectedTheaterDate({
        year: newVal?.$y,
        month: newVal?.$M + 1,
        day: newVal?.$D,
        fullDate: newVal?.$d,
      })
    );
    setSelectedDay([newVal]);
    dispatch(setCouponAppliedSuccessfully(false));
    dispatch(setSelectedTheaterTime(""));
    dispatch(setSelectedTimeSlotInd(-1));
  };

  // Handle month change in the calendar
  const handleMonthChange = (month: any) => {
    if (selectedDate.month !== month?.$M + 1) {
      dispatch(
        setSelectedTheaterDate({
          year: month?.$y,
          month: month?.$M + 1,
          day: month?.$D,
          fullDate: month?.$d,
        })
      );
      dispatch(setSelectedTheaterMonth(month?.$M + 1));
      dispatch(setSelectedTheaterDay(1));
    }
    if (
      selectedDate?.day < new Date().getDate() &&
      selectedDate?.month === new Date().getMonth() + 1
    ) {
      dispatch(setSelectedTheaterDay(new Date().getDate()));
    }
    const isCurrentMonth = month === (new Date().getMonth() + 1) && year === new Date().getFullYear();
    fetchHighlightedDays(month?.$M + 1, month?.$y, locationCode, isCurrentMonth);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          disablePast
          value={
            dayjs(
              `${selectedDate?.year}-${selectedDate?.month}-${selectedDate?.day}`
            ) || new Date()
          }
          loading={isLoading}
          onMonthChange={(newVal) => handleMonthChange(newVal)}
          onChange={(newVal) => handleCalendarDate(newVal)}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays,
              selectedDay,
              startDate,
            } as any,
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
