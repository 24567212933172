import styled from "styled-components";
import Parser from "html-react-parser";

type propsType = {
  title: string;
  imgURL: string;
  description?: [];
  source?: string;
};

const ArticleCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  gap: 1rem;

  @media only screen and (max-width: 786px) {
    margin: 0;
    gap: 0.5rem;
  }
`;

const ArticleHeader = styled.div`
  flex: 1;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin: 1rem 0;

  @media only screen and (max-width: 786px) {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 0;
  }
`;

const ArticleImgContainer = styled.div`
  //   flex: 1;
  background-color: #d9d9d9;
  width: 100%;
  height: 22rem;
  border-radius: 24px;
  margin-bottom: 2rem;

  @media only screen and (max-width: 786px) {
    height: 14rem;
    margin: 1rem 0;
  }
`;

const ArticleDescContainer = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const ArticleSourceContainer = styled.div`
  flex: 0.2;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const LinkContainer = styled.a`
  padding-left: 0.5rem;
`;

const ArticleCard = (props: propsType) => {
  const { title, imgURL, description, source } = props;
  return (
    <ArticleCardContainer>
      <ArticleHeader><h2>{title}</h2></ArticleHeader>
      {imgURL&&<ArticleImgContainer>
        <img
          src={imgURL}
          alt={title}
          title={title}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            objectFit: "cover",
            overflow: "hidden",
          }}
        />
      </ArticleImgContainer>}
      {/* <ArticleDescContainer>{desc}</ArticleDescContainer>
      <ArticleDescContainer>{description2}</ArticleDescContainer>
      <ArticleDescContainer>{description3}</ArticleDescContainer> */}
      {description?.map((desc:any)=>{ return <ArticleDescContainer><>{Parser(desc)}</></ArticleDescContainer> })}
      {source && (
        <ArticleSourceContainer>
          Source:
          <LinkContainer href={source} target="_blank">
            {" "}
            {source}
          </LinkContainer>
        </ArticleSourceContainer>
      )}
    </ArticleCardContainer>
  );
};
export default ArticleCard;
