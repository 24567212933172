import styled from "styled-components";

const RightHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  @media only screen and (max-width: 1000px) {
    margin: 1.5rem 0;
  }
`;

const CustomerReviewText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;

  @media only screen and (max-width: 1000px) {
    font-size: 18px;
    font-weight: 700;
  }
`;

const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;

  @media only screen and (max-width: 1000px) {
    font-size: 16px;
  }
`;

const LeftHalf = styled.div`
  flex: 1;
  display: flex;
  height: 25rem;
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    margin: 0rem 0;
    gap: 0rem;
  }
`;

type propsType = {
  title: string;
  desc: string;
  isBestSeller: boolean;
  imgUrl: string;
  ind: string;
};

const ImgFirst = (props: propsType) => {
  const { title, desc, isBestSeller, imgUrl, ind } = props;
  return (
    <ReviewContainer id= {ind} className={title}>
      <LeftHalf>
        <img
          loading="lazy"
          src={imgUrl}
          alt={title}
          title={title}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "24px",
            objectFit: "cover",
            overflow: "hidden",
          }}
        />
      </LeftHalf>
      <RightHalf>
        <CustomerReviewText>
          {title}
          {isBestSeller && (
            <span
              style={{
                backgroundColor: "#EF1D4A",
                color: "white",
                borderRadius: "18px",
                fontSize: "14px",
                fontWeight: 700,
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
            >
              Best Seller
            </span>
          )}
        </CustomerReviewText>
        <CustomerReviewDesc>{desc}</CustomerReviewDesc>
      </RightHalf>
    </ReviewContainer>
  );
};
export default ImgFirst;
