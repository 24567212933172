import Theaters from "../Theaters/Theaters";
import Header from "../../../../../Home/screens/Header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedLocation,
  getSelectedTheaterDate,
  getTheaterDetails,
  getSelectedCity
} from "../../../../../../redux/selectors/bookingSelector";
import { HiArrowLeft } from "react-icons/hi";
import * as S from "./RightViewSection.style";
import { useEffect, useState } from "react";
import {
  resetActiveCakes,
  resetActiveDecorations,
  resetActiveGifts,
  resetActiveServices,
  resetBookingDetails,
  resetBookingSummaryAddons,
  resetExtraNumberOfPeople,
  resetSelectedOccasion,
  setCouponAppliedSuccessfully,
  setSelectedLocation,
  setTheaterTimeSlots,
} from "../../../../../../redux/actions/bookingActions";
import { PROD } from "../../../../../../endPointsConfig";
import { useSessionManagement } from "../../../../../../tracking/eventTracker";
import Carousel from "react-material-ui-carousel";
import { seoFooterContent } from "../../../../utils";
import { serviceAvailableAtCitiesSelector } from "../../../../../../redux/selectors/webConfigSelector";
import HaveDoubts from "../../../../../Home/screens/HaveDoubts/HaveDoubts";
import { Button, useMediaQuery } from "@mui/material";

const RightViewSection = () => {
  const isMweb = useMediaQuery("(max-width:786px)");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const theaterDetails = useSelector(getTheaterDetails);
  const selectedLocation = useSelector(getSelectedLocation) || {};
  const selectedCity = useSelector(getSelectedCity) || "";
  const selectedDate = useSelector(getSelectedTheaterDate) || {};
  const serviceAvailableAtCities = useSelector(serviceAvailableAtCitiesSelector) || [];
  const [activeTimeSlotInd, setActiveTimeSlotInd] = useState(Array(theaterDetails?.length).fill(-1));

  const theaterCount = (theaterDetails && theaterDetails.length) || 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useSessionManagement("THEATRE");

  useEffect(() => {
    if (
      Object.keys(selectedDate).length === 0 ||
      Object.keys(selectedLocation).length === 0
    )
      return;

    const date = `${selectedDate?.year}${selectedDate?.month}${selectedDate?.day}`;
    const theaterTimeSlots: any = {};
    selectedLocation &&
      selectedLocation.code &&
      selectedLocation.code.length > 0 &&
      selectedLocation.code.map((theaterCode: any) => {
        fetch(`${PROD}/getSlots?code=${theaterCode}_${date}&getDate=false`)
          .then((response) => response.json())
          .then((response) => {
            theaterTimeSlots[theaterCode] = response.slots;
          });
      });
    dispatch(setTheaterTimeSlots(theaterTimeSlots));
    setActiveTimeSlotInd(Array(theaterDetails?.length).fill(-1));
  }, [selectedDate, selectedLocation]);

  function intToEnglish(number:any):string{

    var NS = [
      { value: 100, str: "Hundred" },
      { value: 90, str: "Ninety" },
      { value: 80, str: "Eighty" },
      { value: 70, str: "Seventy" },
      { value: 60, str: "Sixty" },
      { value: 50, str: "Fifty" },
      { value: 40, str: "Forty" },
      { value: 30, str: "Thirty" },
      { value: 20, str: "Twenty" },
      { value: 19, str: "Nineteen" },
      { value: 18, str: "Eighteen" },
      { value: 17, str: "Seventeen" },
      { value: 16, str: "Sixteen" },
      { value: 15, str: "Fifteen" },
      { value: 14, str: "Fourteen" },
      { value: 13, str: "Thirteen" },
      { value: 12, str: "Twelve" },
      { value: 11, str: "Eleven" },
      { value: 10, str: "Ten" },
      { value: 9, str: "Nine" },
      { value: 8, str: "Eight" },
      { value: 7, str: "Seven" },
      { value: 6, str: "Six" },
      { value: 5, str: "Five" },
      { value: 4, str: "Four" },
      { value: 3, str: "Three" },
      { value: 2, str: "Two" },
      { value: 1, str: "One" }
    ];
  
    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return intToEnglish(t) + ' ' + n.str + ' ' + intToEnglish(d);
          } else {
            return intToEnglish(t) + ' ' + n.str;
          }
  
        }
      }
    }
    return result;
  };

  useEffect(() => {
    dispatch(resetBookingSummaryAddons());
    dispatch(resetActiveCakes());
    dispatch(resetActiveDecorations());
    dispatch(resetActiveGifts());
    dispatch(resetActiveServices());
    dispatch(resetExtraNumberOfPeople());
    dispatch(resetBookingDetails());
    dispatch(resetSelectedOccasion());
    dispatch(setCouponAppliedSuccessfully(false));
  }, []);

  useEffect(() =>{
    setActiveTimeSlotInd(Array(theaterDetails?.length).fill(-1));
  },[theaterDetails]);

  return (
    <>
      <S.RightViewSectionContainer>
        {pathname.startsWith("/mweb-private-theaters") ? (
          <Header />
        ) : null}
        <S.RightViewSectionView>
          {pathname.startsWith("/mweb-private-theaters") ? (
            <S.BackButtonContainer onClick={() => navigate(-1)}>
              <HiArrowLeft size={24} />
              <S.BackButtonTxt>Back</S.BackButtonTxt>
            </S.BackButtonContainer>
          ) : null}
          <S.Heading>
           <h1 style={{fontFamily:'inherit', fontSize:'inherit'}}> {intToEnglish(theaterCount)} private theaters in {selectedLocation?.locationName} </h1>
          </S.Heading>
          {theaterDetails &&
            theaterDetails.length > 0 &&
            theaterDetails.map((theater: any, index:any) => {
              return <Theaters key={theater?.code} theaterDetails={theater} index={index} activeTimeSlotInd={activeTimeSlotInd} setActiveTimeSlotInd={setActiveTimeSlotInd}/>;
            })}
        </S.RightViewSectionView>
      </S.RightViewSectionContainer>
      <S.RightViewLinkContainer>
        <S.LinkHeading><b>Other branches in {selectedCity}</b></S.LinkHeading>
        <S.LinkContainer>
          {serviceAvailableAtCities && 
            serviceAvailableAtCities?.length > 0 &&
            serviceAvailableAtCities?.map((city:any)=>{
              if(city?.cityName?.toLowerCase() === selectedCity?.toLowerCase()){
                return (city?.locations?.length > 0 &&
                city?.locations.map((location:any)=> {
                  if(location?.locationName !== selectedLocation?.locationName){
                  return (<>
                  <Button style={{backgroundColor:"#ffffff", width: "48%", margin:"1%", whiteSpace: "nowrap", textAlign: "center", padding: "0.5rem 0", textTransform: "none"}}>
                    <Link
                        to={`/private-theaters-in-${selectedCity.toLowerCase()}/${location?.locationName.toLowerCase()}`}
                        title={`${location?.locationName} Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town`}
                        style={{ color: "#34255C", textAlign: 'center', textDecoration: "underline"}}   
                        onClick={()=>{dispatch(setSelectedLocation(location))}}                   
                    >
                      {location?.locationName}
                    </Link></Button>
                    </>);
                  }
                }) 
              )
            }
            }
          )}
        </S.LinkContainer>
      </S.RightViewLinkContainer>
      {isMweb && <HaveDoubts/>}
      <S.Footer>
        <Carousel
          interval={3000}
          navButtonsAlwaysVisible={true}
          indicatorContainerProps={{
            style: {
              display: "none",
            },
          }}
        >
          {seoFooterContent &&            
            seoFooterContent[selectedLocation?.locationName as keyof typeof seoFooterContent] &&
            seoFooterContent[selectedLocation?.locationName as keyof typeof seoFooterContent]
            .map((item: any, i: any) => {
              return (
                <S.CarouselView key={`carousel_${i}`}>
                  <h2><S.FooterText>{item.name}</S.FooterText></h2>
                  <h3><S.FooterSubText>{item.description}</S.FooterSubText></h3>
                </S.CarouselView>
              );
            })}
        </Carousel>
      </S.Footer>
    </>
  );
};
export default RightViewSection;
