export const GiftsList = {
  "Rose Bouquet": {
    id: 1,
    name: "Rose Bouquet",
  },
  "Small Teddy Bear": {
    id: 2,
    name: "Small Teddy Bear",
  },
  "Small Heart Pillow": {
    id: 3,
    name: "Small Heart Pillow",
  },
};
