import styled from "styled-components";

export const Heading = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
  }
`;

export const RightViewSectionContainer = styled.div`
  @media only screen and (max-width: 786px) {
    // padding: 2rem 1rem;
  }
`;
export const RightViewSectionView = styled.div`
  @media only screen and (max-width: 786px) {
    padding: 1rem 1rem 0rem 1rem;
  }
`;

export const BackButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  @media only screen and (max-width: 786px) {
    margin-bottom: 0.5rem;
  }
`;

export const BackButtonTxt = styled.div``;

export const CarouselView = styled.div``;

export const Footer = styled.div`
  background-color: #edecea;
  padding: 2rem 4rem 4rem 4rem;

  @media only screen and (max-width: 786px) {
    padding: 2rem 2rem;
  }
`;

export const FooterText = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  @media only screen and (max-width: 786px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const FooterSubText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin: 1rem 0;

  @media only screen and (max-width: 786px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const LinkContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  
  @media only screen and (max-width: 786px) {
    margin-top: 0.3rem;
    padding-bottom: 1rem;
  }
`;

export const LinkHeading = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
`;

export const RightViewLinkContainer = styled.div`
  background-color: #edecea;
  padding: 1rem;
`;