import styled from "styled-components";

type propsTypes = {
  type?: any;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  styles?: object;
};

const BookNowBtn = styled.button`
  display: flex;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#E5E5E5" : "#34265c")};
  color: ${(props) => (props.disabled ? "#7F7F7F" : "#ffffff")};
  width: 12.5rem;
  height: 3rem;
  border: none;
  border-radius: 64px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  font-family: Montserrat;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
`;

const ButtonView = (props: propsTypes) => {
  const { text, type, onClick, disabled, styles } = props;
  return (
    <BookNowBtn
      type={type}
      onClick={onClick}
      disabled={disabled}
      style={{ ...styles }}
    >
      {text}
    </BookNowBtn>
  );
};
export default ButtonView;
