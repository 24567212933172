import styled from "styled-components";

export const WaitListFormContainer = styled.div`
  display: flex;
  flex-direction: row;

  // tablet and mobile
  @media only screen and (max-width: 786px) {
    flex-direction: column;
  }
`;

export const Heading = styled.div`
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  line-height: 49px;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const RightHalf = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 6rem 9rem;
  @media only screen and (max-width: 786px) {
    padding: 2.5rem 1.5rem;
  }
`;

export const LeftHalf = styled.div`
  flex: 1.5;
  display: flex;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const FormInstruction = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 2rem;

  @media only screen and (max-width: 786px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const MwebFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const LabelBox = styled.label``;

export const InputContainer = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const RowContainer = styled.div`
  display: flex;
  justity-content: space-between;
  align-items: space-between;
  gap: 2rem;

  @media only screen and (max-width: 786px) {
    flex: 1;
  }
`;

export const NumberOfPeopleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0rem;
`;

export const ModalHeading = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
`;

export const ModalHeadingTxt = styled.div``;

export const ModalDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;
