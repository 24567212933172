import styled from "styled-components";
import { useState } from "react";
import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import Luxury_1 from "../../../assets/images/Luxury_1.jpeg";
import Select from "react-select";
import { IoPricetagOutline } from "react-icons/io5";
import { LuUsers } from "react-icons/lu";
import { FaRegClock } from "react-icons/fa6";
import CelebrationIcon from "../../../assets/images/Pricing/Celebrations.svg";

import { FoodBeverages } from "../../Booking/screens/BookingOverview/components/DecorationSelection/FoodBeverages";
import { Link } from "react-router-dom";
import ButtonView from "../../../components/common/ButtonView";

const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2rem 1.5rem;
  }
`;

const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  /* border: 1px solid black; */

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const RightHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  //   justify-content: center;
`;

const LeftHalf = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  // padding: 6rem 0 0 0;
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 3rem;

  @media only screen and (max-width: 1000px) {
    // flex-direction: column;
  }
`;

const MwebReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 3rem;

  @media only screen and (max-width: 1000px) {
    // flex-direction: column;
  }
`;

const LocationSelection = styled.div`
  background-color: #edecea;
  display: flex;
  border-radius: 48px;
  justify-content: space-around;
  align-items: stretch;
`;

const Location = styled.div`
  border-radius: 48px;
  flex: 1;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SelctedLocation = styled.div`
  background-color: #34255c;
  color: white;
  border-radius: 48px;
  flex: 1;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LocationForm = styled.div`
  display: flex;
  padding-top: 3rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    padding: 0;
  }

  @media only screen and (max-width: 786px) {
    padding: 0;
    flex: 1;
  }
`;

const TheaterGalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2rem 0;
  border-bottom: 1px solid #c8c8c8;
  gap: 5rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const TheaterHeading = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const TheaterDetailsContainer = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  gap: 1rem;
  //   padding: 1rem 0;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const AddonsContainer = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  //   padding: 1rem 0;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const options = [
  { value: "koramangla", label: "Koramangla" },
  { value: "rajajiNagar", label: "Rajaji Nagar" },
  { value: "banashankri", label: "Banashankri" },
  { value: "whitefield", label: "Whitefield" },
  { value: "nagavara", label: "Nagavara" },
];

const LabelBox = styled.label`
  margin: 1rem 1rem 0 0;
`;

const HeadingContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const HeadingTxt = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  @media only screen and (max-width: 786px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

const OptionalContainer = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  align-items: flex-end;
`;

const IconContainer = styled.img`
  width: 5rem;
  height: 5rem;
`;

const CakeView = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 786px) {
    // justify-content: space-between;
  }
`;

const OccasionView = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 786px) {
  }
`;


const ListHeading = styled.div`
  padding-bottom: 1rem;
  line-height: 24px;
`;

const UlList = styled.ul`
  line-height: 24px;
  margin: 0;

  @media only screen and (max-width: 786px) {
    margin: 0.5rem;
  }
`;

const List = styled.li`
  margin: 1rem 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media only screen and (max-width: 786px) {
    margin: 0;
  }
`;

const InfoContainer = styled.div`
  margin: 1rem 0 0 0;
`;

const InfoHeading = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  display: inline;
  margin: 1rem 0 0 0;


  @media only screen and (max-width: 786px) {
    font-weight: 500;
  }
`;

const InfoPara = styled.div`  
  display: inline;
`;

const Info = styled.div`
  padding: 0 0 1rem 0;
`;

const ButtonContainer = styled.div`
  flex: 0.5;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const Pricing = () => {
  const isDweb = useMediaQuery("(min-width:786px)");
  const [isActiveCity, setActiveCity] = useState("Delhi");

  const handleActiveCity = (event: any) => {
    setActiveCity(event);
  };

  return (
    <>
      <Header />
      <Banner />

      <PricingContainer>
        <Heading>How does The Binge Town pricing work?</Heading>
        <InfoContainer>
          <ListHeading>
          Our pricing is very simple and comprises of four major categories. Let's deep dive into each of them in detail:
          </ListHeading>
            <Info>
              <InfoHeading>Theater price: </InfoHeading>
              <InfoPara>This is the charge that you pay for the private theater space in which you get Air conditioned space for private screening equipped with HD Screen, Speaker, comfortable seating, etc. Please note that we do not provide any movies or OTT accounts. We will do the setup using your account and content. In some theaters and for all 1.5 hr slots, decoration is mandatory so theater price includes decoration charges also.</InfoPara>
            </Info>          
            <Info>
              <InfoHeading>Decoration price: </InfoHeading>
              <InfoPara>This is the charge you pay for decoration of the private theater. If you book without decoration then you might get a completely empty theater space without any decoration. In some theaters and for all 1.5 hr slots, decoration is mandatory so theater price includes decoration charges also. We have some extra decoration options available like Rose Heart, Candle Path and LED Number which are separately charged and optional.</InfoPara>
            </Info>
            <Info>
              <InfoHeading>Add-ons: </InfoHeading>
              <InfoPara>This is the charge of other additional services that you can book depending on your budget and requirements like cake, gift, photoshoot, fog entry, etc.</InfoPara>
            </Info>
            <Info>
              <InfoHeading>Food and beverages: </InfoHeading>
              <InfoPara>This is the charge of food and beverages items that you order at the theatre during your booking. F&B cannot be pre-ordered and must be ordered at the theatre only. Different branches have different menus and you can find these menus in the branch selection page.</InfoPara>
            </Info>
        </InfoContainer>
        <ButtonContainer>
        <Link
          to="/private-theaters-booking"
          title="City Page | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
          style={{ textDecoration: "none", color: "#ffffff" }}
        >
          <ButtonView text={"Book Now"} />
        </Link>
      </ButtonContainer>
        {/* <LocationSelection>
          {isActiveCity === "Bangalore" ? (
            <SelctedLocation onClick={(event) => handleActiveCity("Bangalore")}>
              Bangalore
            </SelctedLocation>
          ) : (
            <Location onClick={(event) => handleActiveCity("Bangalore")}>
              Bangalore
            </Location>
          )}
          {isActiveCity === "Hyderabad" ? (
            <SelctedLocation onClick={(event) => handleActiveCity("Hyderabad")}>
              Hyderabad
            </SelctedLocation>
          ) : (
            <Location onClick={(event) => handleActiveCity("Hyderabad")}>
              Hyderabad
            </Location>
          )}
          {isActiveCity === "Delhi" ? (
            <SelctedLocation onClick={() => handleActiveCity("Delhi")}>
              Delhi
            </SelctedLocation>
          ) : (
            <Location onClick={() => handleActiveCity("Delhi")}>Delhi</Location>
          )}
        </LocationSelection>
        <LocationForm>
          <LabelBox>Location : </LabelBox>
          <Select
            placeholder="Select here"
            options={options}
            styles={{
              menuList: (styles) => ({
                ...styles,
                padding: 0,
              }),
              control: (styles) => ({
                ...styles,
                backgroundColor: "white",
                display: "flex",
                flex: 1,
                width: isDweb ? "22rem" : "",
                height: "3rem",
                borderRadius: "12px",
                paddingBottom: 0,
                paddingTop: 0,
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  // ...styles,
                  backgroundColor: "#edecea",
                  padding: "0.5rem",
                  height: "2rem",
                  display: "flex",
                  alignItems: "center",
                  borderBotom: "1px solid #cacaca",
                };
              },
            }}
          />
        </LocationForm>
        <ReviewContainer>
          <TheaterHeading>Total : 2 Theaters</TheaterHeading>
          <TheaterGalleryContainer>
            <LeftHalf>
              <img
                src={Luxury_1}
                alt="About us"
                title="About us"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "20px",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              />
            </LeftHalf>
            <RightHalf>
              <TheaterHeading>Standard Theater</TheaterHeading>
              <TheaterDetailsContainer>
                <DetailContainer>
                  <IoPricetagOutline />
                  &#8377; 1,299 for 4 people
                  <OptionalContainer>(including taxes)</OptionalContainer>
                </DetailContainer>
                <DetailContainer>
                  <IoPricetagOutline />
                  &#8377; 300 per person for 4+ members
                </DetailContainer>
                <DetailContainer>
                  <LuUsers />6 people max
                </DetailContainer>
                <DetailContainer>
                  <FaRegClock />3 hrs
                </DetailContainer>
                <DetailContainer>
                  <img src={CelebrationIcon} alt="celebration" title="celebration" />
                  Decoration is included
                </DetailContainer>
              </TheaterDetailsContainer>
              <AddonsContainer>
                <HeadingContainer>
                  <HeadingTxt>Add-ons</HeadingTxt>
                  <OptionalContainer>(charged separately)</OptionalContainer>
                </HeadingContainer>

                <CakeView>
                  {FoodBeverages &&
                    FoodBeverages.length > 0 &&
                    FoodBeverages.map((service: any, ind: Number) => {
                      return (
                        <OccasionView key={`service_${ind}`}>
                          <IconContainer src={service.imgUrl} />
                        </OccasionView>
                      );
                    })}
                  <div>+ and much more</div>
                </CakeView>
              </AddonsContainer>
            </RightHalf>
          </TheaterGalleryContainer>
          <TheaterGalleryContainer>
            <LeftHalf>
              <img
                src={Luxury_1}
                alt="About us"
                title="About us"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "20px",
                  objectFit: "cover",
                  overflow: "hidden",
                }}
              />
            </LeftHalf>
            <RightHalf>
              <TheaterHeading>Romantic Theater</TheaterHeading>
              <TheaterDetailsContainer>
                <DetailContainer>
                  <IoPricetagOutline />
                  &#8377; 1,299 for 4 people
                  <OptionalContainer>(including taxes)</OptionalContainer>
                </DetailContainer>
                <DetailContainer>
                  <IoPricetagOutline />
                  &#8377; 300 per person for 4+ members
                </DetailContainer>
                <DetailContainer>
                  <LuUsers />6 people max
                </DetailContainer>
                <DetailContainer>
                  <FaRegClock />3 hrs
                </DetailContainer>
                <DetailContainer>
                  <img src={CelebrationIcon} alt="celebration" title="celebration" />
                  Decoration is included
                </DetailContainer>
              </TheaterDetailsContainer>
              <AddonsContainer>
                <HeadingContainer>
                  <HeadingTxt>Add-ons</HeadingTxt>
                  <OptionalContainer>(charged separately)</OptionalContainer>
                </HeadingContainer>

                <CakeView>
                  {FoodBeverages &&
                    FoodBeverages.length > 0 &&
                    FoodBeverages.map((service: any, ind: Number) => {
                      return (
                        <OccasionView key={`service_${ind}`}>
                          <IconContainer src={service.imgUrl} />
                        </OccasionView>
                      );
                    })}
                  <div>+ and much more</div>
                </CakeView>
              </AddonsContainer>
            </RightHalf>
          </TheaterGalleryContainer>
        </ReviewContainer>
        <MwebReviewContainer></MwebReviewContainer> */}
      </PricingContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default Pricing;
