import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Banner from "../../Home/screens/Banner/Banner";
import CopyRight from "../../Home/screens/CopyRight/CopyRight";
import Footer from "../../Home/screens/Footer/Footer";
import Header from "../../Home/screens/Header/Header";
import { IoCloseOutline } from "react-icons/io5";

import { CgArrowLongRight } from "react-icons/cg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setArticles, setBlogs } from "../../../redux/actions/blogsActions";
import { getBlogs } from "../../../redux/selectors/blogsSelector";
import { BlogsList } from "./BlogsList";
import { fetchBlogs } from "../../../apis/ConfigAPI";
import * as S from "./Blogs.style";
import { PROD } from "../../../endPointsConfig";
import { Helmet, HelmetProvider } from "react-helmet-async";
import seoStrings from "../../../seoSrings";
import { helmetJsonLdProp } from "react-schemaorg";
import { WebPage } from "schema-dts";

const Category = styled.div<{ $active?: boolean }>`
  background-color: ${(props) => (props.$active ? "#34265c" : "#edecea")};
  color: ${(props) => (props.$active ? "#ffffff" : "#000000")};
  border-radius: 32px;
  padding: 0.5rem 2rem;
  margin: 1rem 2rem 3rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 786px) {
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
  }
`;

const BlogTitle = styled.div``;
const BlogCloseBtn = styled.div``;

const BlogsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [blogsData, setBlogsData] = useState<any>([]);
  const [activeBlog, setActiveBlog] = useState<any>("");
  const [error, setError] = useState("");

  const blogs = useSelector(getBlogs);

  useEffect(() => {
    fetchBlogs()
      .then((response) => {
        dispatch(setBlogs(response));
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  useEffect(() => {
    let tempBlogsData = [];
    for (let blog in blogs) {
      tempBlogsData.push(...blogs[blog]);
    }
    setBlogsData(tempBlogsData);
    setActiveBlog("");
  }, [blogs]);

  useEffect(() => {
    if (activeBlog === "") return;
    setBlogsData(blogs[activeBlog]);
  }, [activeBlog]);

  const handleActiveBlog = (blog: any) => {
    setActiveBlog(blog);
    setBlogsData(blogs[blog]);
  };

  const handleBlogClicked = (blogId: any) => {
    let heading:any = "";
    fetch(`${PROD}/getBlogs?blogId=${blogId}`)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        dispatch(setArticles(response));
        heading = response.heading.replaceAll(' ','-');        
        navigate(`${blogId}/${heading}`);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const handleClose = (blog: any) => {
    setActiveBlog("");
  };

  return (
    <>
      <HelmetProvider>
        <Helmet
           script={[
            helmetJsonLdProp<WebPage>({
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Blogs List",
                "description": "Blogs about places and activities in Bangalore, Hyderabad and Delhi.",
                "url": "https://thebingetown.com/blogs"
            }),
          ]}
        >
          <title>{seoStrings['blogs']['title']}</title>
          <meta
                name="description"
                content={seoStrings['blogs']['description']}
            />
          <meta
                name="keywords"
                content={seoStrings['blogs']['keyword']}
            />
        </Helmet>
      </HelmetProvider>
      <Header />
      <Banner />
      <S.GalleryPageContainer>
        <S.Heading>Blogs</S.Heading>
        <S.CategoryContainer>
          {Object.keys(blogs).map((blog) => {
            return (
              <Category
                key={blog}
                onClick={() => handleActiveBlog(blog)}
                $active={blog === activeBlog}
              >
                <BlogTitle>{BlogsList[blog] ? BlogsList[blog] : blog}</BlogTitle>
                <BlogCloseBtn>
                  <IoCloseOutline size={24} onClick={() => handleClose(blog)} />
                </BlogCloseBtn>
              </Category>
            );
          })}
        </S.CategoryContainer>
        <S.ReviewContainer>
          {blogsData &&
            blogsData.length > 0 &&
            blogsData.map((blog: any) => {
              return (
                <S.ImgContainer
                  key={blog.blogID}
                  onClick={() => handleBlogClicked(blog.blogID)}
                >
                  <S.TopHalf>
                    <img
                      src={blog?.imageURL}
                      alt={blog?.heading}
                      title={blog?.heading}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "20px 20px 0 0",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    />
                  </S.TopHalf>
                  <S.BottomHalf>
                    <S.TextContainer>{blog?.heading}</S.TextContainer>
                    <S.MoreInfoContainer>
                      <S.ReadText>Read</S.ReadText>
                      <S.RightArrow>
                        <CgArrowLongRight size={24} />
                      </S.RightArrow>
                    </S.MoreInfoContainer>
                  </S.BottomHalf>
                </S.ImgContainer>
              );
            })}
        </S.ReviewContainer>
      </S.GalleryPageContainer>

      <Footer />
      <CopyRight />
    </>
  );
};
export default BlogsPage;
