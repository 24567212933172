import useMediaQuery from "@mui/material/useMediaQuery";
import Carousel from "react-material-ui-carousel";
import { default as MultiCarousel } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { IoIosStar } from "react-icons/io";
import { getCustomerReviews } from "../../../../redux/selectors/webConfigSelector";
import "./Slider.css";
import * as S from "./CustomerReview.style";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 3,
  },
};

const CustomDot = ({ onMove, index, onClick, active }: any) => {
  return (
    <li
      className={active ? "active" : "inactive"}
      onClick={() => onClick()}
    ></li>
  );
};

const CustomerReviews = () => {
  const isMweb = useMediaQuery("(max-width:786px)");
  const customerReviews = useSelector(getCustomerReviews) || [];
  return (
    <>
      <S.CustomerReviewsContainer>
        <S.Heading>
          More than 30,000 reviews on Google with 4.9
          <IoIosStar size={24} style={{ color: "orange" }} /> rating!
        </S.Heading>
        <S.ReviewContainer>
          <MultiCarousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            partialVisible={false}
            centerMode={true}
            dotListClass="custom-dot-list-style"
            customDot={<CustomDot />}
          >
            {customerReviews &&
              customerReviews.length > 0 &&
              customerReviews.map(
                (
                  review: { reviewText: String; reviewBy: String },
                  i: Number
                ) => {
                  return (
                    <S.Review key={`review_${i}`}>
                      <S.CustomerReviewText>
                        {review.reviewText}
                      </S.CustomerReviewText>
                      <S.CustomerReviewBy>
                        - {review.reviewBy}
                      </S.CustomerReviewBy>
                    </S.Review>
                  );
                }
              )}
          </MultiCarousel>
        </S.ReviewContainer>
      </S.CustomerReviewsContainer>
      <S.MwebCustomerReviewsContainer>
        <S.Heading>
          More than 30,000 reviews on Google with 4.9
          <IoIosStar size={24} style={{ color: "orange" }} /> rating!
        </S.Heading>
        <Carousel
          interval={4000}
          navButtonsWrapperProps={{
            style: {
              top: "8.5rem",
            },
          }}
          indicatorContainerProps={{
            style: {
              paddingBottom: "2rem",
              marginTop: "5.5rem",
            },
          }}
          navButtonsAlwaysVisible={true}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#34255C",
              width: "5rem",
              borderRadius: "8px",
              color: "#34255C",
            },
          }}
        >
          {customerReviews &&
            customerReviews.length > 0 &&
            customerReviews.map(
              (review: { reviewText: String; reviewBy: String }, i: Number) => {
                return (
                  <S.Review key={`review_${i}`}>
                    <S.CustomerReviewText>
                      {review.reviewText}
                    </S.CustomerReviewText>
                    <S.CustomerReviewBy>- {review.reviewBy}</S.CustomerReviewBy>
                  </S.Review>
                );
              }
            )}
        </Carousel>
      </S.MwebCustomerReviewsContainer>
    </>
  );
};
export default CustomerReviews;
