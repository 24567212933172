import { forwardRef } from "react";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
} from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {
  setBookingSummaryTheaterCost,
  setExtraNumberOfPeople,
  setSubtotalAmount,
} from "../../../../../../redux/actions/bookingActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getBookingDetails,
  getBookingSummaryTheaterCost,
  getSelectedTheater,
  getShortSlotSelected,
} from "../../../../../../redux/selectors/bookingSelector";
import store from "../../../../../../store";

const NumberInput = forwardRef(function CustomNumberInput(
  props: NumberInputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseNumberInput
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      slotProps={{
        incrementButton: {
          children: <AddIcon fontSize="small" />,
          className: "increment",
        },
        decrementButton: {
          children: <RemoveIcon fontSize="small" />,
        },
      }}
      {...props}
      ref={ref}
      readOnly={true}
    />
  );
});

export default function QuantityInput({
  min = 0,
  max = 99,
  defaultValue,
  setNumberOfpeople,
}: any) {
  const dispatch = useDispatch();

  const selectedTheater = useSelector(getSelectedTheater);
  const isShortSlotSelected = useSelector(getShortSlotSelected);
  const bookingDetails = useSelector(getBookingDetails);
  const bookingTheaterCost = useSelector(getBookingSummaryTheaterCost);
  const {
    maxPerson,
    avgPerson,
    costPerExtraPerson,
    costPerExtraPersonShort,
    decorCost,
  } = selectedTheater;
  const { isDecorationSelected = false } = bookingDetails || {};
  const {
    name,
    price: theaterCost,
    isDecorCostAdded,
  } = bookingTheaterCost || {};

  const handleQuantityChange = (event: any, newValue: any) => {
    event.preventDefault();
    dispatch(setExtraNumberOfPeople(newValue > avgPerson
      ? (newValue - avgPerson)
      : 0));
    const decorationCost = isDecorCostAdded ? decorCost : 0;
    if (isShortSlotSelected) {
      let cost =
        newValue > avgPerson
          ? (newValue - avgPerson) * costPerExtraPersonShort
          : 0;
      dispatch(
        setBookingSummaryTheaterCost({
          name: `Theater `,
          price: selectedTheater.shortSlotPrice + cost,
          isDecorCostAdded,
          decorCost: decorationCost,
          numberOfPeople: newValue,
        })
      );
    } else {
      let cost =
        newValue > avgPerson ? (newValue - avgPerson) * costPerExtraPerson : 0;
      dispatch(
        setBookingSummaryTheaterCost({
          name: `Theater `,
          price: selectedTheater.theatreCost + cost,
          isDecorCostAdded,
          decorCost: decorationCost,
          numberOfPeople: newValue,
        })
      );
    }

    store.dispatch(setSubtotalAmount());
    setNumberOfpeople(newValue);
  };

  return (
    <NumberInput
      //   disableDecrement
      aria-label="Quantity Input"
      min={min}
      max={max}
      defaultValue={defaultValue}
      onChange={(event, newValue) => handleQuantityChange(event, newValue)}
    />
  );
}

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? "#34265c" : "#34265c"};
    border-color: ${theme.palette.mode === "dark" ? "#34265c" : "#34265c"};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
);
