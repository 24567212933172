import styled from "styled-components";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BookingCancelled from "../../../assets/images/BookingCancelled.svg";
import ReactGA from "react-ga";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0rem;
  flex: 1;
`;

const ModalHeading = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: center;
  text-align: center;
`;

const ButtonView = styled.button`
  background-color: #34265c;
  color: white;
  border-radius: 64px;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  padding: 1rem;
  border: none;
  font-family: "Montserrat";
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "16px solid #fffff",
  borderRadius: 4,
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  fontFamily: "Montserrat",
};

const ModalSubText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const isMweb = useMediaQuery("(max-width:786px)");
  const isSmallMweb = useMediaQuery("(max-width:358px)");
  const [modalOpen, setModalOpen] = useState(true);

  const eventTrack = (category: any, action: any, label: any) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };
  eventTrack.bind(
    PaymentSuccess,
    "Success Booking",
    "Success Booking Page",
    "Page"
  );

  const goBackToHome = () => {
    navigate("/");
  };

  return (
    <>
    <div style={{width: '100vw', height: '100vh' }} aria-hidden="false">
    </div>
      <Modal
        open={modalOpen}
        onClose={goBackToHome}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: isSmallMweb ? "70%" : isMweb ? 270 : 400 }}>
          <ModalContainer>
            <ModalHeading>
              <img
                alt="bookingCancelledIcon"
                title="bookingCancelledIcon"
                src={BookingCancelled}
                style={{ width: "40px", height: "40px" }}
              />
            </ModalHeading>
            <ModalHeading>Your payment was successful!</ModalHeading>
            <ModalSubText>
              Your payment was successful! Please check your email or whatsapp
              for booking confirmation and details.
            </ModalSubText>
            <ButtonView onClick={goBackToHome}>Go to Home</ButtonView>
          </ModalContainer>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentSuccess;
