import React from "react";

class Calls {
  static hostname: any = "https://thebingetown.com:3001";
  constructor() {}

  static sendUserData(type: any, userDataObj: any): any {
    return new Promise((resolve, reject) => {
      fetch(`${this.hostname}/collectWebData`, {
        method: "POST",
        body: JSON.stringify({ dataType: type, data: userDataObj }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => {
          if (response.status != 200) {
            return resolve(false);
          }
          return resolve(true);
        })
        .catch((err) => {
          console.log("Coming here CCCCCC");
          return resolve(false);
        });
    });
  }

  static getUserData(phone: any, auth: any): any {
    return new Promise((resolve, reject) => {
      fetch(`${this.hostname}/getUserData?phone=${phone}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Auth: auth,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => {
          console.log(err.message);
          return resolve(false);
        });
    });
  }

  static pushWebhook(): any {
    return new Promise((resolve, reject) => {
      fetch(`${this.hostname}/getOrder`)
        .then((response) => response.json())
        .then((data) => {
          return resolve(data);
        })
        .catch((err) => {
          console.log(err.message);
          return resolve(false);
        });
    });
  }
}

export default Calls;
