import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";
import ButtonView from "../../../../../../components/common/ButtonView";
import PeopleCountIcon from "../../../../../../assets/images/Theaters/PeopleCountIcon.svg";

import TheaterInchIcon from "../../../../../../assets/images/Theaters/TheaterInchIcon.svg";
import TheaterWIcon from "../../../../../../assets/images/Theaters/TheaterWIcon.svg";
import Cake from "../../../../../../assets/images/Theaters/Cake.svg"
import FoodAndBeverages from "../../../../../../assets/images/Theaters/FoodAndBeverages.svg"
import Refund from "../../../../../../assets/images/Theaters/Refund.svg"
import SunIcon from "../../../../../../assets/images/Theaters/SunIcon.svg";
import MoonIcon from "../../../../../../assets/images/Theaters/MoonIcon.svg";
import YoutubeIcon from "../../../../../../assets/images/Theaters/YoutubeIcon.svg";
import Carousel from "react-material-ui-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedTheater,
  setSelectedTheaterTime,
  setSelectedTimeSlotInd,
  setShortSlotSelected,
} from "../../../../../../redux/actions/bookingActions";
import {
  getSelectedLocation,
  getSelectedTheaterDate,
  getTheaterTimeSlots,
} from "../../../../../../redux/selectors/bookingSelector";

import * as S from "./Theaters.style";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const TimeSlot = styled.button<{ $active?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.$active ? "#34265c" : props.disabled ? "#E5E5E5" : "#eaecfa"};
  color: ${(props) => (props.$active ? "#ffffff" : "#000000")};
  border: none;
  border-radius: 8px;
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  gap: 0.5rem;
  font-family: Montserrat;
`;

const DisableTimeSlot = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5;
  color: #000000;
  border: none;
  border-radius: 8px;
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  gap: 0.5rem;
  font-family: Montserrat;
  text-decoration: line-through;
`;

const TimeIcon = styled.div<{ $active?: boolean }>`
  // background-color: ${(props) => (props.$active ? "#ffffff" : "#000000")};
`;

const MwebTimeSlot = styled.div`
  display: flex;
  gap: 1rem;
`;

const MwebTimeSlotStrikeThrough = styled.div`
  display: flex;
  gap: 1rem;
  text-decoration: line-through;
`;

const MWebTimeIcon = styled.div``;
const MwebTimeTxt = styled.div``;

const Theaters = ({ theaterDetails, index, activeTimeSlotInd, setActiveTimeSlotInd}:any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMweb = useMediaQuery("(max-width:786px)");
  const theaterTimeSlots = useSelector(getTheaterTimeSlots);
  const selectedLocation = useSelector(getSelectedLocation);
  const selectedDate = useSelector(getSelectedTheaterDate);
  const [proceedDisable, setProceedDisable] = useState(true);

  const handleSelectedTimeSlot = (time: string, ind: number) => {
    if (ind === shortSlotInd) {
      dispatch(setShortSlotSelected(true));
    } else {
      dispatch(setShortSlotSelected(false));
    }
    let newActiveTimeSlotInd = [...activeTimeSlotInd];
    newActiveTimeSlotInd.forEach((val, i) => {
      newActiveTimeSlotInd[i]=-1;
    });
    newActiveTimeSlotInd[index]=ind;
    setActiveTimeSlotInd(newActiveTimeSlotInd);
    dispatch(setSelectedTheaterTime(time));
    dispatch(setSelectedTimeSlotInd(ind));
  };

  const handleMwebSelectedTimeSlot = (e: any) => {
    let ind = timeOptions.findIndex((option: any) => option.value === e.value);
    if (!disabledTheaterTimeSlots.includes(ind)) {
      setProceedDisable(false);
    } else {
      setProceedDisable(true);
    }
    if (ind === shortSlotInd) {
      dispatch(setShortSlotSelected(true));
    } else {
      dispatch(setShortSlotSelected(false));
    }
    let newActiveTimeSlotInd = [...activeTimeSlotInd];
    newActiveTimeSlotInd.forEach((val, i) => {
      newActiveTimeSlotInd[i]=-1;
    });
    newActiveTimeSlotInd[index]=ind;
    setActiveTimeSlotInd(newActiveTimeSlotInd);
    dispatch(setSelectedTheaterTime(e?.value));
    dispatch(setSelectedTimeSlotInd(ind));
  };

  const handleProceed = () => {
    dispatch(setSelectedTheater(theaterDetails));
    const theaterCode = theaterDetails.code;
    navigate(`booking_overview/${theaterCode}`);
  };

  const [timeOptions, setTimeOptions] = useState<any>([]);

  const disabledTheaterTimeSlots = theaterTimeSlots[theaterDetails?.code] || [];
  const totalTheaterTimeSlots = theaterDetails?.times?.length || 0;

  const videoSampleUrl = theaterDetails?.video || "";
  const shortSlotInd = theaterDetails?.shortSlot || -1;
  const shortSlotExist = theaterDetails?.shortSlotExist || -1;

  const { times = [] } = theaterDetails || {};
  const { year, month, day } = selectedDate || {};

  // const [disabledTheaterTimeSlots, setDisableTheaterTimeSlots] = useState<
  //   Number[]
  // >([]);
  // const [theTimeSlots, setTheaterTimeSlots] = useState<any>({});

  // useEffect(() => {
  //   setActiveTimeSlotInd(-1);
  // }, [selectedDate, selectedLocation]);

  const remainingSlots =
    totalTheaterTimeSlots - disabledTheaterTimeSlots?.length || 0;

  const getTimeIcon = (date: any) => {
    let [startTime, endTime] = date?.split?.(" - ");
    const startTimeValue = startTime?.split?.(" ")?.[0]?.split?.(":")?.[0];
    const startTimeAMPM = startTime?.split?.(" ")?.[1];
    const endTimeValue = endTime?.split?.(" ")?.[0]?.split?.(":")?.[0];
    const endTimeAMPM = endTime?.split?.(" ")?.[1];
    if (
      (startTimeValue > 6 && startTimeAMPM === "AM") ||
      (endTimeValue <= 6 && endTimeAMPM === "PM")
    ) {
      return "SunIcon";
    } else {
      return "MoonIcon";
    }
  };

  useEffect(() => {
    let options: any[] = [];
    theaterDetails &&
      theaterDetails.times.length > 0 &&
      theaterDetails.times.map((time: any, ind: number) => {
        const timeIcon = getTimeIcon(time);
        if (disabledTheaterTimeSlots.includes(ind)) {
          options.push({
            label: (
              <MwebTimeSlotStrikeThrough>
                <MWebTimeIcon>
                  {timeIcon === "SunIcon" ? (
                    <img src={SunIcon} alt="SunIcon" title="SunIcon"/>
                  ) : (
                    <img src={MoonIcon} alt="MoonIcon" title="MoonIcon"/>
                  )}
                </MWebTimeIcon>

                <MwebTimeTxt>{time}</MwebTimeTxt>
              </MwebTimeSlotStrikeThrough>
            ),
            value: time,
          });
        } else {
          options.push({
            label: (
              <MwebTimeSlot>
                <MWebTimeIcon>
                  {timeIcon === "SunIcon" ? (
                    <img src={SunIcon} alt="SunIcon" title="SunIcon"/>
                  ) : (
                    <img src={MoonIcon} alt="MoonIcon" title="MoonIcon"/>
                  )}
                </MWebTimeIcon>

                <MwebTimeTxt>{time}</MwebTimeTxt>
              </MwebTimeSlot>
            ),
            value: time,
          });
        }
      });
    setTimeOptions(options);
  }, [theaterDetails?.times, disabledTheaterTimeSlots]);

  const theaterCost = theaterDetails.isDecorationMandatory
    ? theaterDetails?.theatreCost + theaterDetails?.decorCost
    : theaterDetails?.theatreCost;

  return (
    <S.TheatersContainer>
      <S.TheaterView>
        <S.LeftView>
          <Carousel
            interval={3000}
            indicatorContainerProps={{
              style: {
                width: "100%",
                position: "relative",
                zIndex: 1,
                marginTop: "-3.3rem"
              },
            }}
            indicatorIconButtonProps={{
                style: {
                    color: '#ffffff'       // 3
                }
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    backgroundColor: "#a6a6a6", // 2
                    color: '#bfbfbf'
                }
            }}
          >
            {theaterDetails?.pics?.map((pic: any, ind: Number) => {
              return (
                <S.CarouselView key={`pic_${ind}`}>
                  <S.ViewSampleVideoContainer
                    onClick={() => window.open(videoSampleUrl)}
                  >
                    <S.ViewSampleVideoIcon>
                      <img src={YoutubeIcon} alt="youtubeIcon" title="youtubeIcon"/>
                    </S.ViewSampleVideoIcon>
                    <S.ViewSampleVideoTxt>
                      Sample video
                    </S.ViewSampleVideoTxt>
                  </S.ViewSampleVideoContainer>
                  <Zoom>
                  <S.ImageContainer>
                    <img
                      key={pic.index}
                      src={pic.url}
                      alt={pic.altText}
                      title={pic.altText}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: isMweb ? "24px 24px" : "24px 0",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    />
                  </S.ImageContainer>
                  </Zoom>
                </S.CarouselView>
              );
            })}
          </Carousel>
        </S.LeftView>
        <S.RightView>
          <S.TitleContainer>
            <S.Title>{theaterDetails?.name}</S.Title>
            <S.MoreInfoAboutSlots>
              {remainingSlots > 3 ? (
                <S.SlotsAvailable>
                  {remainingSlots} slots available
                </S.SlotsAvailable>
              ) : (
                <S.MinSlotsAvailable>
                  {remainingSlots} slots available
                </S.MinSlotsAvailable>
              )}
            </S.MoreInfoAboutSlots>
          </S.TitleContainer>
          <S.PriceContainer>
            {theaterDetails?.theatreCost ? (
              <S.PriceContainerText>
                <S.Price>
                  &#8377; {theaterCost} for upto {theaterDetails?.avgPerson}{" "}
                  people{" "} 
                </S.Price>
                <S.TaxInclude>{" "} (including Taxes) </S.TaxInclude>
              </S.PriceContainerText>
            ) : null}

            {/* {theaterDetails?.costPerExtraPerson ? (
            <S.ExtraChargeContainer>
              <S.ExtraChargePrice>
                &#8377; {theaterDetails?.costPerExtraPerson}
              </S.ExtraChargePrice>
              <S.ExtraChargeText> per extra person</S.ExtraChargeText>
            </S.ExtraChargeContainer>
          ) : null} */}
          </S.PriceContainer>
          <S.PeopleInfo>
            <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip-1" > Maximum Theater Capacity Is {theaterDetails?.maxPerson} people </Tooltip>}
                >
              <S.MaxLimit>Max {theaterDetails?.maxPerson} people </S.MaxLimit>
            </OverlayTrigger>
            {theaterDetails?.isDecorationMandatory ? (
              <> 
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip-2" > Decoration charges is included in theater price </Tooltip>}
                >
                  <S.MaxLimit style={{ backgroundColor: "#DACDF4" }}>
                    Decoration Included
                  </S.MaxLimit>
                </OverlayTrigger>
              </>
            ) : (
              <OverlayTrigger            
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip-2" > Decoration charges is &#8377;{theaterDetails?.decorCost}</Tooltip>}
              >
                <S.MaxLimit style={{ backgroundColor: "#DACDF4" }}>
                  Decoration - &#8377;{theaterDetails?.decorCost}
                </S.MaxLimit>
              </OverlayTrigger>
            )}
          </S.PeopleInfo>
          <S.MoreDetails>
            <S.Details>
              <img src={PeopleCountIcon} alt="peopleCountIcon" title="peopleCountIcon"/>
              {theaterDetails?.maxPerson <= 4 && theaterDetails?.code != 'RN_CT' ? (
                <> Extra people not allowed</>
              ) : (
                <>
                  &#8377; {theaterDetails?.costPerExtraPerson}/extra person after {theaterDetails?.avgPerson} people
                </>
              )}
            </S.Details>
            <S.Details>
              <S.MoreDtail>
                <img src={TheaterInchIcon} alt="theaterInchIcon" title="theaterInchIcon"/>
                {theaterDetails?.screenSize}
              </S.MoreDtail>
              <S.MoreDtail>
                <img src={TheaterWIcon} alt="theaterWIcon" title="theaterWIcon"/>
                {theaterDetails?.speakerSize}
              </S.MoreDtail>
            </S.Details>
            {!isMweb &&
            <>
              <S.Details>                          
                <img src={Cake} alt="Cake" title="Cake"/>
                Add cakes and gifts in next step
              </S.Details>
              <S.Details>
                <img src={FoodAndBeverages} alt="FoodAndBeverages" title="FoodAndBeverages"/>
                Food can be ordered at theater
              </S.Details>
              <S.Details>
                <img src={Refund} alt="Refund" title="Refund"/>
                Free cancellation upto before 72 hrs of slot
              </S.Details>
            </>
            }
          </S.MoreDetails>
        </S.RightView>
      </S.TheaterView>
      <S.FooterView>
          {isMweb &&
          <S.MoreDetails>
            <S.Details>                          
                <img src={Cake} alt="Cake" title="Cake"/>
                Add cakes and gifts in next step
              </S.Details>
              <S.Details>
                <img src={FoodAndBeverages} alt="FoodAndBeverages" title="FoodAndBeverages"/>
                Food can be ordered at theater
              </S.Details>
              <S.Details>
                <img src={Refund} alt="Refund" title="Refund"/>
                Free cancellation upto before 72 hrs of slot
              </S.Details>
          </S.MoreDetails>
          }
        <S.TimeSlots>
          <S.TimeSlotLabel>Choose a time slot:</S.TimeSlotLabel>
          <S.TimeSlotsAvailable>
            {times.length > 0 &&
              times.map((time: any, ind: number) => {
                const isDisabled = disabledTheaterTimeSlots?.includes(ind);
                return (
                  <React.Fragment key={`time_slot${ind}`}>
                    {isDisabled ? (
                      <DisableTimeSlot key={ind} disabled={isDisabled}>
                        <TimeIcon $active={activeTimeSlotInd[index] == ind}>
                          {getTimeIcon(time) === "SunIcon" ? (
                            <img src={SunIcon} alt="sunIcon" title="sunIcon"/>
                          ) : (
                            <img src={MoonIcon} alt="moonIcon" title="moonIcon"/>
                          )}
                        </TimeIcon>

                        <S.TimeTxt>{time}</S.TimeTxt>
                      </DisableTimeSlot>
                    ) : (
                      <TimeSlot
                        key={ind}
                        onClick={() => handleSelectedTimeSlot(time, ind)}
                        $active={activeTimeSlotInd[index] == ind}
                      >
                        <TimeIcon $active={activeTimeSlotInd[index] == ind}>
                          {getTimeIcon(time) === "SunIcon" ? (
                            <img src={SunIcon} alt="sunIcon" title="sunIcon"/>
                          ) : (
                            <img src={MoonIcon} alt="moonIcon" title="moonIcon"/>
                          )}
                        </TimeIcon>

                        <S.TimeTxt>{time}</S.TimeTxt>
                      </TimeSlot>
                    )}
                  </React.Fragment>
                );
              })}
          </S.TimeSlotsAvailable>
        </S.TimeSlots>
        <S.MwebTimeSlotsAvailable>
          <S.MwebTimeSlotLabel>Choose a time slot:</S.MwebTimeSlotLabel>
          <Select
            placeholder="Select here"
            isSearchable={false}
            options={timeOptions}
            onChange={handleMwebSelectedTimeSlot}
            styles={{
              menu: (styles) => ({
                zIndex: 3,
                borderBotom: "1px solid #cacaca",
                borderRadius: "12px",
              }),
              menuList: (styles) => ({
                ...styles,
                backgroundColor: "#edecea",
                borderRadius: "12px",
              }),
              control: (styles) => ({
                ...styles,
                backgroundColor: "#ffffff",
                flex: 1,
                padding: "0.5rem 0",
                margin: "0.7rem 0",
                borderRadius: "12px",
                borderColor: "#352e2e"
              }),
              option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                return {
                  // ...styles,
                  backgroundColor: "#edecea",
                  padding: "0.5rem",
                  height: "2rem",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #cacaca",
                };
              },
            }}
          />
        </S.MwebTimeSlotsAvailable>

        <S.BookingAction>
          <ButtonView
            text="Proceed"
            disabled={
              (!isMweb && (activeTimeSlotInd.length === 0 || activeTimeSlotInd[index] === -1)) ||
              remainingSlots === 0 ||
              (isMweb && (proceedDisable || activeTimeSlotInd.length === 0 || activeTimeSlotInd[index] === -1))
            }
            onClick={handleProceed}
            styles={{ width: isMweb ? "100%" : "" }}
          />
          <S.TipContainer>
            (Just pay <b>&#8377;{theaterDetails?.advance} advance</b> to book)
          </S.TipContainer>
        </S.BookingAction>
      </S.FooterView>
    </S.TheatersContainer>
  );
};

export default Theaters;
