import styled from "styled-components";

export const WaitListFormContainer = styled.div`
  display: flex;
  flex-direction: row;

  // // tablet and mobile
  @media only screen and (max-width: 786px) {
    flex-direction: column;
  }
`;

export const Heading = styled.div`
  //   flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  /* border: 1px solid black; */
  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const RightHalf = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  // background-color: lightgrey;
  padding: 6rem 9rem;

  @media only screen and (max-width: 1210px) {
    padding: 2.5rem 1.5rem;
  }
`;

export const LeftHalf = styled.div`
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
  // padding: 6rem 0 0 0;

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BookNowBtn = styled.button`
  display: flex;
  justify-content: center;
  background-color: #34265c;
  color: white;
  height: 3rem;
  border: none;
  border-radius: 64px;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  font-family: Montserrat;
  width: 85%;
  margin: 2rem 0;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const FormInstruction = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  @media only screen and (max-width: 786px) {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 1rem;
  }
`;

export const OTPVerificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  gap: 1rem;
`;

export const InputBox = styled.input`
  margin: 0.7rem 0;
  border-radius: 12px;
  padding: 0.8rem;
  width: 3rem;
  text-align: center;
  border: 1px solid black;

  @media only screen and (max-width: 400px) {
    width: 2.5rem;
  }
`;
export const ErrorContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;
