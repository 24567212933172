export const serviceAvailableAtCitiesSelector = (state) => {
  return state.webConfig.homePage.serviceAvailableAtCities;
};

export const locationCodeMapSelector = (state) => {
  return state.webConfig.locationCodeMap;
};

export const getOurServicesImagesUrl = (state) => {
  return state?.webConfig?.ourServicePage?.serviceImagesURL;
};

export const getAboutUsImageUrl = (state) => {
  return state?.webConfig?.aboutUsPage?.aboutUsImageURL;
};

export const getContactUsPage = (state) => {
  return state?.webConfig?.contactUsPage;
};

export const getRefundPolicyPage = (state) => {
  return state?.webConfig?.refundPolicyPage;
};

export const getMainContainerInfo = (state) => {
  return state?.webConfig?.homePage?.mainContainer;
};

export const getCities = (state) => {
  return state?.webConfig?.cities;
};

export const getOurServices = (state) => {
  return state?.webConfig?.homePage?.ourServices;
};

export const getCustomerReviews = (state) => {
  return state?.webConfig?.homePage?.customerReview;
};

export const getGalleryImages = (state) => {
  return state?.webConfig?.homePage?.GalleryImages;
};

export const getWhyChooseUsImages = (state) => {
  return state?.webConfig?.homePage?.whyChooseUsImages;
};
