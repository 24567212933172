import styled, { css } from "styled-components";

export const AboutUsContainer = styled.div`
  display: flex;
  padding: 6rem 9rem;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 786px) {
    padding: 2rem 1.5rem;
    display: none;
  }
`;

export const MwebAboutUsContainer = styled.div`
  display: flex;
  padding: 6rem 9rem;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 786px) {
    padding: 2rem 1.5rem;
  }
  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const LeftHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled.div`
  font-size: 40px;
  font-weight: 600;
  line-height: 48.76px;

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const SubText = styled.div`
  padding-top: 3rem;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;

  @media only screen and (max-width: 786px) {
    padding-top: 1.5rem;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Description = styled.div`
  margin: 1rem 0;
  font-size: 18px;
  font-weight: 400;
  padding-top: 1rem;
  line-height: 26px;

  @media only screen and (max-width: 786px) {
    padding-top: 0;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const RightHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem 0 0 4rem;
`;

export const ImgContainer = styled.div`
  background-color: #d9d9d9;
  flex: 1;
  display: flex;
  border-radius: 20px;
  height: 27rem;
`;

export const MwebImgContainer = styled.div`
  background-color: #d9d9d9;
  border-radius: 20px;
  height: 11rem;
  margin-top: 1.5rem;
`;

export const CarouselSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 29rem;
`;

export const BookNowBtnContainer = styled.div`
  padding-top: 8rem;
  flex: 0.5;
  display: flex;

  @media only screen and (max-width: 786px) {
    padding: 1rem 0rem;
    flex: 1;
  }
`;

export const BookNowBtn = styled.div`
  display: flex;
  justify-content: center;
  background-color: #34265c;
  color: white;
  width: 12rem;
  height: 3rem;
  border-radius: 64px;
  align-items: center;

  @media only screen and (max-width: 786px) {
    flex: 1;
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  bottom: 1.8rem;
  position: relative;
`;

export const ArrowButton = styled.button`
  all: unset;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
  width: 40px;
  height: 40px;
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  // @media only screen and (max-width: 768px) {
  //   flex-direction: column;
  // }
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem 1.5rem;
`;
