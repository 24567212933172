import ButtonView from "../../../../../../components/common/ButtonView";
import Select from "react-select";
import useMediaQuery from "@mui/material/useMediaQuery";
import CouponAppliedTick from "../../../../../../assets/images/BookingOverview/CouponAppliedTick.svg";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import {
  setBookingDetails,
  setBookingDetailsFormValid,
  setBookingSummaryCoupon,
  setBookingSummaryTheaterCost,
  setCouponAppliedSuccessfully,
  setSelectedOccasion,
  setSubtotalAmount,
} from "../../../../../../redux/actions/bookingActions";
import {
  getBookingDetails,
  getBookingSummaryTheaterCost,
  getCouponInfo,
  getIsCouponAppliedSuccessfully,
  getSelectedTheater,
  getSelectedTheaterDate,
  getSelectedTheaterTime,
  getSelectedTimeSlotInd,
  getShortSlotSelected,
} from "../../../../../../redux/selectors/bookingSelector";
import QuantityInput from "./QuantityInput";

import * as S from "./BookingDetails.style";
import { PROD } from "../../../../../../endPointsConfig";
import { getUpdateBookingDetail } from "../../../../../../redux/selectors/blogsSelector";
import { serviceAvailableAtCitiesSelector } from "../../../../../../redux/selectors/webConfigSelector";
import { fetchWebConfig } from "../../../../../../apis/ConfigAPI";
import { setWebConfig } from "../../../../../../redux/actions/webConfigActions";
import store from "../../../../../../store";
import { useSessionManagement } from "../../../../../../tracking/eventTracker";
import Calls from "../../../../../../apis/Calls";

const options = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

const decorationMadantoryOptions = [
  {
    value: "yes",
    label: "Yes",
  },
];

const BookingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let isCouponApplied = useSelector(getIsCouponAppliedSuccessfully);
  const bookingDetails = useSelector(getBookingDetails) || {};
  const selectedTheater = useSelector(getSelectedTheater);
  const selectedDate = useSelector(getSelectedTheaterDate);
  const selectedTheaterTime = useSelector(getSelectedTheaterTime);
  const selectedTimeSlotInd = useSelector(getSelectedTimeSlotInd);
  const bookingSummaryTheaterCost = useSelector(getBookingSummaryTheaterCost);
  const shortSlotSelected = useSelector(getShortSlotSelected);
  const coupon = useSelector(getCouponInfo);
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isBookingDetailsValid, setIsBookingDetailsValid] = useState(false);
  const [isCouponTouched, setIsCouponTouched] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const serviceAvailableAtCities =
    useSelector(serviceAvailableAtCitiesSelector) || [];

  const updateBookingDetail = useSelector(getUpdateBookingDetail) || {};
  const isUpdateBooking = Object.keys(updateBookingDetail)?.length > 0;
  const [decorationDefaultOption, setDecorationDefaultOption] = useState(
    options[0]
  );

  useSessionManagement("FORM");

  useEffect(() => {
    if (Object.keys(serviceAvailableAtCities)?.length === 0) {
      fetchWebConfig()
        .then((response) => {
          dispatch(setWebConfig(response));
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [serviceAvailableAtCities]);
  const {
    bookingName: reduxBookingName,
    whatsappNumber: reduxWhatsappNumber,
    emailAddress: reduxEmailAddress,
  } = bookingDetails || {};

  const isMweb = useMediaQuery("(max-width:786px)");

  const [formData, setFormData] = useState({
    bookingName:
      reduxBookingName !== ""
        ? reduxBookingName
        : updateBookingDetail?.name || "",
    whatsappNumber:
      reduxWhatsappNumber !== ""
        ? reduxWhatsappNumber
        : updateBookingDetail?.phoneNum || "",
    emailAddress:
      reduxEmailAddress !== ""
        ? reduxEmailAddress
        : updateBookingDetail?.email || "",
    isDecorationSelected: true,
    coupon: "",
  });

  useEffect(() => {
    if (
      !bookingDetails ||
      (Object.keys(bookingDetails)?.length === 0 &&
        Object.keys(updateBookingDetail).length > 0)
    ) {
      setFormData({
        bookingName: updateBookingDetail?.name,
        whatsappNumber: updateBookingDetail?.phoneNum,
        emailAddress: updateBookingDetail?.email,
        isDecorationSelected: updateBookingDetail?.decorPrice > 0,
        coupon: updateBookingDetail?.coupon,
      });
    }
  }, [updateBookingDetail]);

  const { name, price, isDecorCostAdded, numberOfPeople } =
    bookingSummaryTheaterCost || {};
  const { code: couponCode, price: couponPrice = 0 } = coupon || {};

  const {
    name: theaterName,
    maxPerson,
    location: theaterLocation,
    avgPerson,
    code: theaterCode,
    costPerExtraPerson,
    costPerExtraPersonShort,
    decorCost,
    shortSlotPrice,
    theatreCost,
    isDecorationMandatory,
  } = selectedTheater || {};
  const defaultPersonVal = numberOfPeople || 2;
  const { year, month, day } = selectedDate;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (isCouponApplied && name === "coupon") {
      setIsCouponTouched(true);
      setCouponError(false);
    }
    if(name === "whatsappNumber" && value.length === 10) {
      navigate(`?whatsappNumber=${value}`);
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const decorationOptions =
    isDecorationMandatory || shortSlotSelected
      ? decorationMadantoryOptions
      : options;

  useEffect(() => {
    if (
      formData?.bookingName?.length > 0 &&
      formData?.whatsappNumber?.length > 0 &&
      formData?.emailAddress?.length > 0
    ) {
      setIsBookingDetailsValid(true);
      dispatch(setBookingDetails(formData));
      dispatch(setBookingDetailsFormValid(true));
    } else {
      setIsBookingDetailsValid(false);
      dispatch(setBookingDetailsFormValid(false));
    }
  }, [formData]);

  function handleFormSubmit(e: any) {
    e.preventDefault();
    const formElement = e.target;
    const isValid = formElement.checkValidity();
    const { isDecorationSelected } = formData;

    setIsFormValid(isValid);

    dispatch(setBookingDetails(formData));
    if (isValid && !isDecorationSelected) {
      Calls.sendUserData("UserData", {
        name: formData.bookingName,
        date: `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`.toString(),
        time: selectedTheaterTime,
        location: theaterLocation,
        theatre: theaterName,
        email: formData?.emailAddress,
        phoneNum: formData?.whatsappNumber,
        status: "FORM_FILL",
      });

      if (isUpdateBooking)
        navigate(`/terms_conditions_agreement/${updateBookingDetail?.id}`);
      else navigate("/terms_conditions_agreement");
    }
    if (isValid && isDecorationSelected) {
      Calls.sendUserData("UserData", {
        name: formData.bookingName,
        date: `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`.toString(),
        time: selectedTheaterTime,
        location: theaterLocation,
        theatre: theaterName,
        email: formData?.emailAddress,
        phoneNum: formData?.whatsappNumber,
        status: "FORM_FILL",
      });
      navigate("choose_occasion");
    }
  }

  const handleCouponApply = () => {
    fetch(`${PROD}/checkCoupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        coupon: formData?.coupon,
        date: `${year}-${month}-${day}`,
        code: `${theaterCode}_${year}${month}${day}_${selectedTimeSlotInd}`,
        isDecor: formData.isDecorationSelected
      }),
    })
      .then((response) => {
        if (response?.status === 200) {
          dispatch(setCouponAppliedSuccessfully(true));
          setIsCouponTouched(false);
        } else {
          dispatch(setCouponAppliedSuccessfully(false));
          setCouponError(true);
        }

        return response.json();
      })
      .then((response) => {
        if (response.resp === "false") {
          dispatch(setCouponAppliedSuccessfully(false));
          setCouponErrorMessage(response?.msg || "Please apply valid coupon code");
          setCouponError(true);
        } else if (response.resp === "true" && response.value) {
          dispatch(
            setBookingSummaryCoupon({
              code: formData?.coupon,
              price: response?.value,
            })
          );
        }
      })
      .catch((error: any) => {
        setError(error);
      });
  };

  const handleDecoration = (event: any) => {
    if (event?.value.toLowerCase() === "no") {
      setFormData((prevState) => ({
        ...prevState,
        isDecorationSelected: false,
      }));

      dispatch(setBookingSummaryCoupon({
              code: "",
              price: 0,
            })
      );
      dispatch(setCouponAppliedSuccessfully(false));  
      dispatch(
        setBookingSummaryTheaterCost({
          name: `Theater `,
          price: bookingSummaryTheaterCost?.price,
          isDecorCostAdded: false,
          decorCost: 0,
          numberOfPeople: bookingSummaryTheaterCost?.numberOfPeople,
        })
      );
      setDecorationDefaultOption({ value: "no", label: "No" });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        isDecorationSelected: true,
      }));
      dispatch(
        setBookingSummaryTheaterCost({
          name: `Theater `,
          price: bookingSummaryTheaterCost?.price,
          isDecorCostAdded: true,
          decorCost: decorCost,
          numberOfPeople: bookingSummaryTheaterCost?.numberOfPeople,
        })
      );
      setDecorationDefaultOption({ value: "yes", label: "Yes" });
    }
    store.dispatch(setSubtotalAmount());
  };

  const handleNumberOfPeople = (numberOfPeople: Number) => {};

  useEffect(() => {
    const { decorPrice, decorType } = updateBookingDetail;
  
    if (decorPrice === 0 || decorType === "None") {
      setDecorationDefaultOption(prevOption => {
        if (prevOption.value !== "no") {
          return { value: "no", label: "No" };
        }
        return prevOption;
      });
    } else {
      setDecorationDefaultOption(prevOption => {
        if (prevOption.value !== "yes") {
          return { value: "yes", label: "Yes" };
        }
        return prevOption;
      });
      dispatch(
        setSelectedOccasion({
          name: decorType,
          price: decorPrice,
        })
      );
    }
  }, [updateBookingDetail.decorPrice, updateBookingDetail.decorType]);

  return (
    <>
      <S.BookingDetailsContainer>
        <S.BookingDetailsHeading>Booking Details</S.BookingDetailsHeading>
        <form onSubmit={handleFormSubmit}>
          <S.FirstRowContainer>
            <S.InputContainer>
              <S.LabelBox htmlFor="bookingName">Booking Name &#x2a;</S.LabelBox>
              <S.InputBox
                type="text"
                placeholder="Type here"
                style={{ width: "90%" }}
                value={formData?.bookingName}
                defaultValue={updateBookingDetail?.bookingName}
                required
                name="bookingName"
                onChange={handleChange}
              />
            </S.InputContainer>
            <S.InputContainer>
              <S.LabelBox>Number of people</S.LabelBox>

              <S.NumberOfPeopleContainer>
                <QuantityInput
                  min={2}
                  max={maxPerson}
                  defaultValue={
                    updateBookingDetail?.numberPeople || defaultPersonVal
                  }
                  setNumberOfpeople={handleNumberOfPeople}
                />
              </S.NumberOfPeopleContainer>
            </S.InputContainer>
          </S.FirstRowContainer>

          <S.SecondRowContainer>
            <S.InputContainer>
              <S.LabelBox htmlFor="whatsappNumber">
                Whatsapp Number &#x2a;
              </S.LabelBox>
              <S.InputBox
                placeholder="Type here"
                type="text"
                style={{ width: "90%" }}
                maxLength={10}
                minLength={10}
                //pattern="\d{10}"
                defaultValue={updateBookingDetail?.phoneNum}
                value={formData?.whatsappNumber}
                required
                name="whatsappNumber"
                //title="Please enter a valid 10-digit whatsapp number"
                onChange={handleChange}
              />
            </S.InputContainer>

            <S.InputContainer>
              <S.LabelBox htmlFor="emailAddress">Email ID &#x2a;</S.LabelBox>
              <S.InputBox
                placeholder="Type here"
                type="email"
                name="emailAddress"
                //pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                defaultValue={updateBookingDetail?.email}
                //title="Please enter a valid email address"
                value={formData?.emailAddress}
                onChange={handleChange}
                required
              />
            </S.InputContainer>
          </S.SecondRowContainer>

          <S.ThirdRowContainer>
            <S.LabelBox>Do you want decoration? &#x2a;</S.LabelBox>
            {updateBookingDetail?.decorType === "None" ? (
              <Select
                placeholder="Select here"
                options={decorationOptions}
                required
                onChange={(event) => handleDecoration(event)}
                value={{
                  label: decorationDefaultOption?.label,
                  value: decorationDefaultOption?.value,
                }}
                styles={{
                  menuList: (styles) => ({
                    ...styles,
                    padding: 0,
                  }),
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    flex: 1,
                    padding: "0.3rem 0",
                    margin: "0.7rem 0",
                    borderRadius: "12px",
                    borderBottom: "1px solid #CACACA",
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      // ...styles,
                      backgroundColor: "#edecea",
                      padding: "0.5rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #cacaca",
                    };
                  },
                }}
                isSearchable={false}
              />
            ) : (
              <Select
                placeholder="Select here"
                options={decorationOptions}
                required
                onChange={(event) => handleDecoration(event)}
                defaultValue={{
                  label: "Yes",
                  value: "yes",
                }}
                styles={{
                  menuList: (styles) => ({
                    ...styles,
                    padding: 0,
                  }),
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: "white",
                    flex: 1,
                    padding: "0.3rem 0",
                    margin: "0.7rem 0",
                    borderRadius: "12px",
                    borderBottom: "1px solid #CACACA",
                  }),
                  option: (
                    styles,
                    { data, isDisabled, isFocused, isSelected }
                  ) => {
                    return {
                      // ...styles,
                      backgroundColor: "#edecea",
                      padding: "0.5rem",
                      height: "2rem",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #cacaca",
                    };
                  },
                }}
                isSearchable={false}
              />
            )}
          </S.ThirdRowContainer>

          {!isUpdateBooking && <S.ThirdRowContainer style={{ marginBottom: "2rem" }}>
            <S.InputContainer>
              <S.LabelBox htmlFor="coupon">Coupon code</S.LabelBox>
              <S.CouponContainer>
                <S.InputBox
                  placeholder="Select here"
                  name="coupon"
                  defaultValue={updateBookingDetail?.coupon}
                  value={
                    isCouponApplied && !isCouponTouched
                      ? couponCode
                      : formData?.coupon
                  }
                  onChange={handleChange}
                  style={{ borderRadius: "12px 0 0 12px" }}
                />
                {isCouponApplied && !isCouponTouched ? (
                  <S.CouponAppliedState>
                    Applied
                    <img
                      src={CouponAppliedTick}
                      style={{ marginLeft: "0.5rem" }}
                      alt="couponAppliedTick"
                      title="couponAppliedTick"
                    />
                  </S.CouponAppliedState>
                ) : (
                  <S.CouponState onClick={handleCouponApply}>
                    Apply
                  </S.CouponState>
                )}
              </S.CouponContainer>
              {!isCouponApplied && couponError ? (
                <S.CouponErrorTxtContainer>
                  <HiOutlineExclamationCircle size={18} />
                  <S.CouponErrorTxt>
                    {couponErrorMessage}
                  </S.CouponErrorTxt>
                </S.CouponErrorTxtContainer>
              ) : (
                ""
              )}
            </S.InputContainer>
          </S.ThirdRowContainer>}
          <ButtonView
            type="submit"
            text="Proceed"
            // onClick={handleFormSubmit}
            disabled={!isBookingDetailsValid}
          />
        </form>
      </S.BookingDetailsContainer>

      <S.MwebBookingDetailsContainer>
        <S.BookingDetailsHeading>Booking Details</S.BookingDetailsHeading>
        <form onSubmit={handleFormSubmit}>
          <S.MwebFormContainer>
            <S.MwebInputContainer>
              <S.LabelBox>Booking Name &#x2a;</S.LabelBox>
              <S.InputBox
                placeholder="Type here"
                style={{ width: "90%" }}
                value={formData?.bookingName}
                defaultValue={updateBookingDetail?.bookingName}
                minLength={3}
                required
                name="bookingName"
                onChange={handleChange}
              />
            </S.MwebInputContainer>

            <S.MwebInputContainer>
              <S.LabelBox>Number of people</S.LabelBox>

              <S.NumberOfPeopleContainer>
                <QuantityInput
                  min={2}
                  max={maxPerson}
                  defaultValue={
                    updateBookingDetail?.numberPeople || defaultPersonVal
                  }
                  setNumberOfpeople={handleNumberOfPeople}
                  readOnly={true}
                />
              </S.NumberOfPeopleContainer>
            </S.MwebInputContainer>

            <S.MwebInputContainer>
              <S.LabelBox>Whatsapp Number &#x2a;</S.LabelBox>
              <S.InputBox
                placeholder="Type here"
                type="text"
                maxLength={10}
                minLength={10}
                pattern="\d{10}"
                style={{ width: "90%" }}
                defaultValue={updateBookingDetail?.phoneNum}
                value={formData?.whatsappNumber}
                required
                name="whatsappNumber"
                onChange={handleChange}
              />
            </S.MwebInputContainer>

            <S.MwebInputContainer>
              <S.LabelBox>Email ID &#x2a;</S.LabelBox>
              <S.InputBox
                placeholder="Type here"
                type="email"
                name="emailAddress"
                defaultValue={updateBookingDetail?.email}
                value={formData?.emailAddress}
                onChange={handleChange}
              />
            </S.MwebInputContainer>

            <S.MwebInputContainer>
              <S.LabelBox>Do you want decoration? &#x2a;</S.LabelBox>
              {updateBookingDetail?.decorType === "None" ? (
                <Select
                  placeholder="Select here"
                  options={decorationOptions}
                  required
                  onChange={(event) => handleDecoration(event)}
                  value={{
                    label: decorationDefaultOption?.label,
                    value: decorationDefaultOption?.value,
                  }}
                  styles={{
                    menuList: (styles) => ({
                      ...styles,
                      padding: 0,
                    }),
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      flex: 1,
                      padding: "0.3rem 0",
                      margin: "0.7rem 0",
                      borderRadius: "12px",
                      borderBottom: "1px solid #CACACA",
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        // ...styles,
                        backgroundColor: "#edecea",
                        padding: "0.5rem",
                        height: "2rem",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #cacaca",
                      };
                    },
                  }}
                  isSearchable={false}
                />
              ) : (
                <Select
                  placeholder="Select here"
                  options={decorationOptions}
                  required
                  onChange={(event) => handleDecoration(event)}
                  defaultValue={{
                    label: "Yes",
                    value: "yes",
                  }}
                  styles={{
                    menuList: (styles) => ({
                      ...styles,
                      padding: 0,
                    }),
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      flex: 1,
                      padding: "0.3rem 0",
                      margin: "0.7rem 0",
                      borderRadius: "12px",
                      borderBottom: "1px solid #CACACA",
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        // ...styles,
                        backgroundColor: "#edecea",
                        padding: "0.5rem",
                        height: "2rem",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #cacaca",
                      };
                    },
                  }}
                  isSearchable={false}
                />
              )}
            </S.MwebInputContainer>

            {!isUpdateBooking && <S.MwebInputContainer>
              <S.LabelBox>Coupon code</S.LabelBox>
              <S.CouponContainer>
                <S.InputBox
                  placeholder="Select here"
                  name="coupon"
                  value={
                    isCouponApplied && !isCouponTouched
                      ? couponCode
                      : formData?.coupon
                  }
                  onChange={handleChange}
                  style={{ borderRadius: "12px 0 0 12px" }}
                />
                {isCouponApplied && !isCouponTouched ? (
                  <S.CouponAppliedState>
                    Applied
                    <img
                      src={CouponAppliedTick}
                      style={{ marginLeft: "0.5rem" }}
                      alt="CouponAppliedTick"
                      title="CouponAppliedTick"
                    />
                  </S.CouponAppliedState>
                ) : (
                  <S.CouponState onClick={handleCouponApply}>
                    Apply
                  </S.CouponState>
                )}
              </S.CouponContainer>
              {!isCouponApplied && couponError ? (
                <S.CouponErrorTxtContainer>
                  <HiOutlineExclamationCircle size={18} />
                  <S.CouponErrorTxt>
                    {couponErrorMessage}
                  </S.CouponErrorTxt>
                </S.CouponErrorTxtContainer>
              ) : (
                ""
              )}
            </S.MwebInputContainer>}

            {/* {isMweb ? null : (
            <ButtonView
              text="Proceed"
              // onClick={(event) => handleFormSubmit(event)}
            />
          )} */}
          </S.MwebFormContainer>
        </form>
      </S.MwebBookingDetailsContainer>
    </>
  );
};

export default BookingDetails;
