import styled from "styled-components";

export const FindUsInYourTownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #edecea;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem 3rem 9rem;
  }

  @media only screen and (max-width: 1425px) {
    padding: 6rem 2rem 3rem 2rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 786px) {
    padding: 2rem 1.5rem;
  }
`;

export const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  // padding-bottom: 2rem;
  /* border: 1px solid black; */

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 1rem;
  }
`;

export const SubHeading = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 2rem;
  line-height: 20px;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;

export const Cities = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* border: 1px solid black; */

  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const MwebCityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media only screen and (min-width: 786px) {
    display: none;
  }
`;
