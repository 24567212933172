import styled from "styled-components";
export const GalleryPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 1110px) {
    padding: 2rem 1.5rem;
  }
`;

export const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  /* border: 1px solid black; */

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ImgContainer = styled.div`
  background-color: #d9d9d9;
  border-radius: 20px;
  width: 28rem;
  height: 18rem;
  margin: 1rem 0;

  @media only screen and (max-width: 786px) {
    width: 10rem;
    height: 7rem;
    margin: 0.5rem 0;
  }

  @media only screen and (max-width: 370px) {
    width: 100%;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0;

  @media only screen and (max-width: 786px) {
    padding: 1rem 0;
    flex-direction: column;
  }
`;

export const LocationSelection = styled.div`
  background-color: #edecea;
  display: flex;
  flex-wrap: wrap;
  border-radius: 48px;
  justify-content: space-around;
  align-items: stretch;
`;

export const Location = styled.div`
  border-radius: 48px;
  flex: 1;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const SelctedLocation = styled.div`
  background-color: #34255c;
  color: white;
  border-radius: 48px;
  flex: 1;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LocationForm = styled.div`
  display: flex;
  padding: 3rem 0;

  @media only screen and (max-width: 786px) {
    flex-direction: column;
    flex: 1;
    gap: 1rem;
    padding: 0;
  }
`;

export const TheaterGalleryContainer = styled.div`
  display: flex;
  // flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    // flex-direction: column;
  }
`;

export const TheaterHeading = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  padding: 1rem 0;

  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const LabelBox = styled.label`
  margin: 1rem 1rem 0 0;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0rem;
  flex: 1;
`;

export const ModalHeading = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  justify-content: space-between;
`;

export const ModalHeadingTxt = styled.div``;
