import styled from "styled-components";

const RightHalf = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CustomerReviewText = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
`;

const CustomerReviewDesc = styled.div`
  margin: 1rem 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
`;

const LeftHalf = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 25rem;
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
  gap: 8rem;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    margin: 1rem 0;
  }
`;

type propsType = {
  title: string;
  desc: string;
  isBestSeller: boolean;
  imgUrl: string;
  ind: string
};

const TextFirst = (props: propsType) => {
  const { title, desc, isBestSeller, imgUrl, ind } = props;

  return (
    <ReviewContainer id= {ind} className={title}>
      <RightHalf>
        <CustomerReviewText>
          {title}
          {isBestSeller && (
            <span
              style={{
                backgroundColor: "#EF1D4A",
                color: "white",
                borderRadius: "18px",
                fontSize: "14px",
                fontWeight: 700,
                padding: "0.5rem 1rem",
                margin: "0.5rem",
              }}
            >
              Best Seller
            </span>
          )}
        </CustomerReviewText>
        <CustomerReviewDesc>{desc}</CustomerReviewDesc>
      </RightHalf>
      <LeftHalf>
        <img
          src={imgUrl}
          alt={title}
          title={title}
          loading="lazy"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = "";
          }}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            overflow: "hidden",
            borderRadius: "24px",
          }}
        />
      </LeftHalf>
    </ReviewContainer>
  );
};
export default TextFirst;
