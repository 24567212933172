import styled from "styled-components";
import { Link } from "react-router-dom";

const BannerContainer = styled.div`
  background-color: #ef1d4a;
  color: white;
  text-align: center;
  padding: 0.7rem;

  @media only screen and (max-width: 798px) {
    padding: 0.5rem;
  }
`;

const Banner = () => {
  let date = new Date();
  let day = date.getDay();
  return (
    <BannerContainer>{
      day >= 0 && day <= 4 ?
      <><b>LIMITED TIME OFFER:</b> Use Coupon <b>"LASTMIN"</b> to get <b>Rs 300</b> off for tomorrow slots</>
      :
      <>
      Can't find your preferred slot?
      <span>&nbsp;</span>
      <Link
        to="/join_waitlist"
        title="Join Waitlist | Personal Theater in Bangalore, Hyderabad & Delhi - The Binge Town"
        style={{ textDecoration: "underline", color: "white" }}
      >
        <b>Join our waitlist</b>
      </Link>
      <span>&nbsp;</span>
      and we'll reach out to you</>}
    </BannerContainer>
  );
};
export default Banner;
