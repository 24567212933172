import styled from "styled-components";

export const FAQsContainer = styled.div`
  display: flex;
  flex-direction: column;

  // laptop
  @media only screen and (min-width: 710px) {
    padding: 6rem 9rem;
  }

  // tablet and mobile
  @media only screen and (max-width: 786px) {
    display: none;
  }
`;

export const MwebFAQsContainer = styled.div`
  display: none;

  // tablet and mobile
  @media only screen and (max-width: 786px) {
    display: flex;
    flex-direction: column;
    padding: 2rem 1.5rem;
  }
`;

export const Heading = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 40px;
  padding-bottom: 2rem;
  /* border: 1px solid black; */

  @media only screen and (max-width: 786px) {
    font-size: 24px;
    line-height: 30px;
    padding-bottom: 0rem;
  }
`;

export const Summary = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  @media only screen and (max-width: 786px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Details = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  @media only screen and (max-width: 786px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
