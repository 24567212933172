export const SpecialServicesList = {
  "Bubble spray": {
    id: 1,
    name: "Bubble spray",
  },
  "Fog entry": {
    id: 2,
    name: "Fog entry",
  },
  "Photoshoot (15 min)": {
    id: 3,
    name: "Photoshoot (15 min)",
  },
  "Photoshoot (30 min)": {
    id: 4,
    name: "Photoshoot (30 min)",
  }
};
